.page__audit-info-page {
  position: relative;
  width: 100%;
}

.page__audit-info-page .header {
  height: 60px;
}
.page__audit-info-page .header .sdw__tabs-page-title {
  font-size: 14px;
}

.page__audit-info-page .body .inner-box {
  padding: 18px;
}
.page__audit-info-page .body .inner-box ~ .inner-box {
  border-top: 1px solid #eee;
}
.page__audit-info-page .body .tag {
  display: inline-block;
  width: 76px;
  height: 24px;
  line-height: 24px;
  color: #265CF0;
  background: #eaeffb;
  border-radius: 2px;
  text-align: center;
}
.page__audit-info-page .body .box_wrap {
  display: flex;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding-right: 16px;
  margin-bottom: 16px;
}
.page__audit-info-page .body .box_wrap .flex_wrap {
  flex: 1 1 50%;
  display: inline-flex;
  word-break: break-all;
  padding-right: 20px;
}
.page__audit-info-page .body .title {
  display: inline-block;
  width: 240px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 8px;
  font-weight: 600;
  flex: 1 1 auto;
}
.page__audit-info-page .body .label {
  width: 84px;
  height: 20px;
  color: #999;
  flex: none;
}
.page__audit-info-page .body .label.time {
  width: auto;
  font-size: 12px;
  line-height: 24px;
}
/* 0: 审批中   1：通过   2：驳回 */
.page__audit-info-page .body .label_text.pass_2 {
  color: #ff5e5e;
}
.page__audit-info-page .body .label_text.pass_1 {
  color: #01c39e;
}
.page__audit-info-page .body .label_text.pass_0 {
  color: #265CF0;
}

.page__audit-info-page .footer {
  text-align: center;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.06);
}
.page__audit-info-page .footer:hover {
  background-color: #265CF0;
  color: #fff;
}

.text_ellipsis {
  display: inline-flex;
  max-width: 44%;
  word-break: break-all;
  max-height: 400px;
  text-overflow: ellipsis;
  overflow: overlay;
}

.label_text.text_ellipsis {
  max-width: 80%;
}