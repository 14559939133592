@keyframes changeTimePickerHeight {
  from {
    max-height: 0;
  }
  to {
    max-height: 300px;
  }
}

.comp__time-picker__wrap {
  display: inline-block;
  position: relative;
}

.comp__time-picker__show-time-wrap {
  display: inline-block;
  width: 230px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  font-size: 14px;
}

.comp__time-picker__show-time-wrap.hover {
  border-color: #265cf0;
}

.comp__time-picker-page-wrap {
  position: absolute;
  top: 54px;
  width: 160px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 #aaa;
  z-index: 9;
  overflow-y: hidden;
  animation: changeTimePickerHeight .25s;
}

.comp__time-picker-page-wrap.range-time {
  width: 320px;
}

.comp__time-picker-page-wrap::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #eee;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  top: -6px;
  left: 30px;
  border-bottom-color: #fff;
  border-right-color: #fff;
}

/* .comp__time-picker-page-wrap::after {
  content: '';
  display: inline-block;
  height: 40px;
  width: 90%;
  position: absolute;
  top: 71px;
  left: 10px;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
} */

.comp__time-picker-page__main-body {
  position: relative;
}

.comp__time-picker-page__footer {
  position: relative;
  color: #265cf0;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
  height: 28px;
  font-size: 12px;
}

.comp__time-picker-page__footer > span {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 18px;
  position: absolute;
}

.comp__time-picker-page__footer > span:hover {
  cursor: pointer;
}

.comp__time-picker-page__footer .right {
  position: absolute;
  right: 10px;
}

.comp__time-picker-page__footer .now:hover,
.comp__time-picker-page__footer .right:hover {
  background-color: #265cf0;
  color: #fff;
}

.comp__time-picker-page__footer .right-cancel {
  position: absolute;
  right: 50px;
  color: #999;
}

.comp__time-picker-page__footer .right-cancel:hover {
  color: #265cf0;
}

.comp__time-picker-page__item-wrap {
  display: inline-block;
  width: 32%;
  text-align: center;
  height: 168px;
  overflow-y: overlay;
  margin-right: 1px;
}

.comp__time-picker-page__item-wrap::before,
.comp__time-picker-page__item-wrap::after {
  content: '';
  display: inline-block;
  height: 100px;
}

.comp__time-picker-page__item-label:hover {
  cursor: pointer;
  background-color: #eee;
}

.comp__time-picker-page__item-label {
  font-size: 12px;
  line-height: 36px;
}

.comp__time-picker-page__item-label.current {
  color: #fff;
  background-color: #265cf0;
}

.comp__time-picker-page__item-label.disabled {
  color: #999;
}

.comp__time-picker-page__item-label.disabled:hover {
  cursor: not-allowed;
  background-color: transparent;
}

.comp__time-picker__clear-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(./img/clear@2x.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 14px;
}

.comp__time-picker__clear-icon:hover {
  cursor: pointer;
}

.comp__time-picker__placeholder {
  color: #757575;
}

/* 时间范围css */
.comp__time-picker-range-wrap {
  display: inline-block;
  width: 45%;
}

.comp__time-picker-range-wrap ~ .comp__time-picker-range-wrap {
  margin-left: 22px;
}

.comp__time-picker-range-wrap .comp__time-picker-page__main-body {
  border: 1px solid #eee;
}

.comp__time-picker-range-wrap .time__title {
  text-align: center;
  margin-bottom: 8px;
  font-size: 14px;
}