.data-editor-win-wrap span.label {
  display: inline-block;
  width: 100px;
  float: left;
}

.data-editor-win-wrap span.label.required::before {
  content: '*';
  width: 0;
  display: inline-block;
  color: #FF5E5E;
  position: relative;
  left: -10px;
}

.data-editor-win-wrap > div {
  margin-bottom: 18px;
}



.EditMapData-content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 124px;
  position: relative;
  display: block;
}

.formInput-propertyTableId-add .el-icon-plus, .formInput-propertyTableId-add .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px!important;
  border: none;
  background: #eee;
  vertical-align: bottom;
  border-radius: 24px;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  line-height: none !important;
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: -30px;
}

.formInput-propertyTableId-add .el-icon-minus {
  right: -60px;
}

.formInput-propertyTableId-add .el-icon-minus {
  margin-left: 36px;
  color: rgb(255, 94, 94) !important;
}

.formInput-propertyTableId-add .contentOne {
  display: inline-block;
  position: relative;
  margin-bottom: 8px;
  width: 80%;
  box-sizing: border-box;
}


.formInput-propertyTableId-add .editorTwo-input-map {
  display: inline-block !important;
  width: 50%;
  display: block;
  box-sizing: border-box;
}


.formInput-propertyTableId-add .editorTwo-input-map div{
  width: 100% !important;
  box-sizing: border-box;
  margin-right: 18px;
  padding-right: 8px;
}


.formInput-propertyTableId-add .editorTwo-input-map div input{
  width: 100% !important;
  box-sizing: border-box;
}

.data-editor-win-wrap-map span.label {
  display: inline-block;
  width: 120px !important;
  float: left;
}

.formInput-virtualPropertyTwoList .contentOne {
  display: block;
  overflow: auto;
  position: relative;
  margin-bottom: 8px;
}

.formInput-virtualPropertyTwoList .left {
  width: 44.5%;
  float: left;
  display: block;
}

.formInput-virtualPropertyTwoList .right {
  width: 45%;
  float: left;
  display: block;
}

.formInput-virtualPropertyTwoList .el-icon-plus, .formInput-virtualPropertyTwoList .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px!important;
  border: none;
  background: #eee;
  vertical-align: bottom;
  border-radius: 24px;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  line-height: none !important;
  cursor: pointer;
  position: absolute;
  top: 9px;
  margin-left: 5px;
}

.formInput-virtualPropertyTwoList .el-icon-minus {
  margin-left: 36px;
  color: rgb(255, 94, 94) !important;
}

.formInput-virtualPropertyList .content {
  width: 100%;
  box-sizing: border-box;
  /* padding-left: 100px; */
  position: relative;
  display: block;
}

.formInput-virtualPropertyList .editorTwo-input-map {
  float: left;
  width: 43.5%;
  display: block;
}

.virtualPropertyList-content {
  display: inline-block;
  position: relative;
  margin-bottom: 8px;
  width: 80%;
  box-sizing: border-box;
}

.virtualPropertyList-content-one {
  display: inline-block;
  width: 80%;
  box-sizing: border-box;
}

.virtualPropertyList-content-one .editorTwo-input-map {
  display: inline-block;
  width: calc(50% - 50px);
  box-sizing: border-box;
}

.virtualPropertyList-content-one .editorTwo-input-map span {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
}

.virtualPropertyList-content-one .left span {
  padding-right: 46px;
}

.virtualPropertyList-content-one .editorTwo-input-map span .sdw-select-input__wrap {
  width: 100% !important;
  box-sizing: border-box;
}

.virtualPropertyList-content-two {
  display: inline-block;
  width: 80%;
  box-sizing: border-box;
}


.virtualPropertyList-content-two .editorTwo-input-map {
  display: inline-block;
  width: calc(50% - 50px);
  box-sizing: border-box;
}

.virtualPropertyList-content-two .editorTwo-input-map span {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding-right: 43px;
}

.virtualPropertyList-content-two .editorTwo-input-map .sdw-input__div-wrap {
  width: 100% !important;
  box-sizing: border-box;
}

.virtualPropertyList-content-two .left {
  padding-right: 2px;
}

