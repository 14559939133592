.sdw-select-input__wrap {
  display: inline-block;
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  line-height: 36px;
  padding-right: 36px;
  box-sizing: border-box;
}

.sdw-select__wrap {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 18px;
}

.sdw-select__wrap:focus {
  outline: none;
  border: none;
}

.sdw-select__wrap:disabled {
  cursor: not-allowed;
  color: #ccc;
}

.sdw-error-input {
  border: 1px solid #ff5e5e;
}

.sdw-error-input__tip {
  position: absolute;
  color: #ff5e5e;
  font-size: 12px;
  background-color: #fff;
  line-height: 14px;
  width: 100%;
  bottom: -16px;
}

.sdw-input__div-wrap {
  line-height: 0;
}

.sdw-select__icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  /* 等视觉给好icon后调整位置 */
  top: 14px;
  right: 6px;
  cursor: pointer;
  background-size: cover;
  background-color: #fff;
}

.sdw-select-input-span__wrap.is-multiple-choice .sdw-select__icon {
  top: 38%;
}

.sdw-select__drop-down {
  background-image: url('./img/down.png');
  background-repeat: no-repeat;
  transform-origin: center center;
  transition: transform .3s;
}

.sdw-select__drop-up {
  transform: rotate(-180deg);
}

.sdw-select-clearable {
  background-image: url('./img/clear.png');
  background-repeat: no-repeat;
}

.sdw-select-list-wrap {
  position: absolute;
  background: #fff;
  margin-top: 6px;
  overflow: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
  z-index: 2040;
  border-radius: 4px;
  height: 0;
}

.sdw-select-list-wrap-up {
  height: 220px;
  transition: height .2s;
}

.sdw-select-list-wrap.is-show-to-up {
  top: -230px;
  transition: none;
}

.sdw-select-list__item {
  line-height: 20px;
}

.sdw-select-list__item:hover {
  background: #f5f7fa;
}

.sdw-select-list-wrap > div {
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sdw-select-list-wrap .sdw-select-list__empty-item {
  padding: 0 0 0 10px;
  line-height: 40px;
  background-color: #eee;
}

.operation-label-title {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
  padding: 0 12px;
  margin-right: -4px;
  background: #fff;
  border: 1px solid #eee;
  font-size: 14px;
  color: #262626;
  border-radius: 4px 0 0 4px;
  word-break: keep-all;
}

.sdw-select-input__wrap.sdw-select__show-selected,
.operation-label-title.sdw-select__show-selected {
  border: 1px solid #265cf0;
}

.sdw-select-input-span__wrap {
  line-height: 44px;
  font-size: 14px;
}

.sdw-select-input__multiple-value__span-wrap {
  display: inline-flex;
  background-color: #f0f2f5;
  color: #909399;
  border-radius: 4px;
  padding: 6px 18px 6px 8px;
  margin: 4px 0 0 8px;
  line-height: 18px;
  border: 1px solid #eee;
  position: relative;
  max-width: 88%;
}

.sdw-select-input__multiple-value__span-wrap:hover {
  border: 1px solid #265cf0;
}

.sdw-select-input-span__wrap.is-multiple-choice .sdw-input-tags__clear-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(./img/cancel.png) no-repeat;
  background-size: cover;
  vertical-align: middle;
  position: absolute;
  right: 4px;
  top: 9px;
}

.sdw-select-input-span__wrap.is-multiple-choice .sdw-input-tags__clear-icon:hover {
  cursor: pointer;
}

.sdw-select-input-span__wrap.is-multiple-choice .sdw-select-input__wrap {
  background-color: #fff;
  /* padding: 8px; */
}

.sdw-select-list-wrap > div.sdw-select-list__disabled-item {
  color: #999;
  height: 20px;
  line-height: 20px;
}

.sdw-select-list-wrap > div.sdw-select-list__disabled-item:hover {
  cursor: unset;
}

.sdw-select-list-wrap > div.sdw-select-list__divid-line {
  height: 2px;
  background: #eee;
  padding: 0;
}

/* 多选模式禁用样式 */
.is-multiple-disabled {
    position: relative;
    pointer-events: none;
}

.is-multiple-disabled::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(250, 250, 250, 0.7);
    pointer-events: none;
}

.is-multiple-color {
    color: #000;
}
