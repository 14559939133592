.data-editor-win-wrap span.label {
  display: inline-block;
  width: 100px;
  float: left;
}

.data-editor-win-wrap span.label.required::before {
  content: '*';
  width: 0;
  display: inline-block;
  color: #FF5E5E;
  position: relative;
  left: -10px;
}

.data-editor-win-wrap > div {
  margin-bottom: 18px;
}

.formInput-data_standard .content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 100px;
  position: relative;
  display: block;
}

.formInput-data_standard .content .el-cascader {
  width: 100%;
}

.formInput-data_standard .content .el-cascader input {
  min-height: 42px;
}

.editorDimen .content {
  width: 80%;
  box-sizing: border-box;
  /* padding-left: 100px; */
  position: relative;
  display: inline-block;
  padding: 0;
}

.editorDimen .content .contentOne {
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
}

.editorDimen .content .contentOne .el-icon-plus, .editorDimen .content .contentOne .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px!important;
  border: none;
  background: #eee;
  vertical-align: bottom;
  border-radius: 24px;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  line-height: none !important;
  cursor: pointer;
  position: absolute;
  top: 9px;
}

.editorDimen .content .contentOne .el-icon-minus {
  margin-left: 36px;
  color: rgb(255, 94, 94) !important;
}

.editorDimen .editorTwo-input {
  width: 48% !important;
  float: left;
  box-sizing: border-box;
  margin-right: 8px;
}

.formInput-propertyInfo .content {
  width: 80%;
  box-sizing: border-box;
  /* padding-left: 100px; */
  position: relative;
  display: inline-block;
}

.formInput-propertyInfo .content .editorTwo-input {
  display: block;
  width: 48%;
  float: left;
  box-sizing: border-box;
}


.formInput.formInput-data_standard .content {
  width: 80%;
  display: inline-block;
  padding-left: 0;
}

.editorMain-dimen .formInput-propertyInfo .right .sdw-select-input__wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.right-dimen-propertyInfo input {
  box-sizing: border-box !important;
  height: 42px !important;
}

.right-dimen-propertyInfo i {
  box-sizing: border-box;
  right: 20px;
}