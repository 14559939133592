.machFactory .page-search label {
    width: 80px;
    float: left;
    text-align: left;
    margin-right: 0px;
    font-size: 14px;
}

.machFactory .page-search .el-form-item__content {
    float: left;
    width: calc(100% - 80px);
}

.machFactory .page-search input {
    height: 40px;
    line-height: 40px;
}

.machFactory .page-search .el-form-item {
    margin-right: 40px;
}

.machFactory .seach .el-select {
    float: left;
}

.machFactory .seach .source {
    float: left;
    margin-left: 26px;
}

.DialogShowData-Modifier .el-dialog--tiny {
    width: 80% !important;
}