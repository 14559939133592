.sdw__tabs-page-title,
.sdw__tabs-page-title__liner-current {
  width: 98px;
}

.sdw__tabs-page-title {
  display: inline-block;
  text-align: center;
  font-size: 16px;
}

.sdw__tabs-page-title:hover {
  cursor: pointer;
  color: #265cf0;
}

.sdw__tabs-page-title__liner {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #eee;
}

.sdw__tabs-page-title.current {
  color: #265cf0;
}

.sdw__tabs-page-title__liner-current {
  display: inline-block;
  height: 2px;
  background-color: #265cf0;
  position: absolute;
  top: 0;
  left: 0;
  transition: left .3s;
}

.sdw-tabs-header-wrap {
  background: #fff;
  line-height: 55px;
  box-sizing: border-box;
}