.monitor-eye-detail__title-label {
  display: inline-block;
  width: 56px;
  height: 22px;
  font-size: 14px;
  color: #adadad;
  line-height: 22px;
  margin-right: 20px;
  margin-bottom: 16px;
}

.monitor-eye-detail__title-label__is-effect,
.monitor-eye-detail__title-label__no-effect {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
  margin-right: 4px;
}

.monitor-eye-detail__title-label__is-effect {
  background: #265cf0;
}

.monitor-eye-detail__title-label + span.label-text {
  display: inline-block;
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: sub;
}

.red {
  color: #ff5e5e;
}

.suspend {
  position: absolute;
  padding: 0 12px;
  color: #333;
  font-size: 12px;
  background: #fff;
  text-align: justify;
  box-sizing: border-box;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  z-index: 999;
  word-break: keep-all;
  left: -20px;
}
