.add-AddWin-win__label .label__title {
    display: inline-block;
    width: 80px;
    text-align: left;
    margin-right: 8px;
}
.add-AddWin-win__label ~ .add-AddWin-win__label {
    margin-top: 16px;
}

.add-AddWin-win__label .comp__date-time-picker-page-wrap {
    position: fixed;
}
