.table-box-paging {
    white-space: nowrap;
    padding: 2px 5px;
    color: #262626;
    font-weight: 500;
    margin-top: 16px;
}

.table-box-paging .pageCom {
    display: inline-block;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    box-sizing: border-box;
    margin: 0 10px 0 0;
    position: relative;
}

.table-box-paging .pageCom .sdw-select-input__wrap {
    width: 110px !important;
    height: 100%;
    box-sizing: border-box;
    line-height: 30px;
}

.table-box-paging .pageCom .sdw-select-input__wrap input {
    height: 20px !important;
    text-align: center;
}

.table-box-paging .pageCom .sdw-select__wrap {
    min-width: 20px;
}

.table-box-paging .pageCom .sdw-select-input__wrap i {
    top: 8px !important;
}

.table-box-paging .pageCount select {
    padding-right: 25px;
    border-radius: 3px;
    height: 100%;
    border: 1px solid #dcdfe6;
}

.table-box-paging .pageCount option {
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #f5f7fa;
}

.table-box-paging .pageCount .sdw-select-list__item {
    text-align: center !important;
}

.table-box-paging .seqPage ul {
    display: inline-block;
    margin: 0;
    padding-left: 10px;
}

.table-box-paging .seqPage li {
    display: inline-block;
    padding: 0 4px;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}

.table-box-paging .seqPage li:hover {
    color: #265cf0;
}

.table-box-paging .gotoPage input {
    width: 50px;
    line-height: 18px;
    padding: 0 2px;
    height: 28px;
    text-align: center;
    margin: 0 2px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #ccc;
}

.table-box-paging .more {
    color: #262626;
    font-weight: 900;
    font-size: 15px;
}

.table-box-paging .lastNext{
    width: 8px;
    height: 8px;
    margin-top: 10px;
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
}

.table-box-paging .last {
    border-bottom: 1px solid #9E9E9E;
    border-left: 1px solid #9E9E9E;
    transform: rotate(45deg);
    float: left;
}

.table-box-paging .next {
    border-bottom: 1px solid #9E9E9E;
    border-right: 1px solid #9E9E9E;
    transform: rotate(-45deg);
    float: right;
}

.table-box-paging .last:hover {
    border-bottom: 1px solid #265cf0;
    border-left: 1px solid #265cf0;
}

.table-box-paging .next:hover {
    border-bottom: 1px solid #265cf0;
    border-right: 1px solid #265cf0;
}

.table-box-paging .seqPageLi {
    display: inline-block;
}

.pageCom.pageCount > span {
    line-height: 28px;
}