.derived-detail__wrap .base-info,
.derived-detail__wrap .standard-log {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}

.derived-detail__wrap .base-info__title {
  padding: 16px 20px;
  line-height: 28px;
  font-size: 18px;
  color: #262626;
  border-bottom: 1px solid #eee;
}

.base-info__title .iconfont.icon-setup,
.base-info__title .iconfont.icon-edit,
.iconfont.icon-edit {
  color: #265cf0;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.derived-detail__wrap .base-info .base-info__img {
  margin-left: 8px;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
}

.derived-detail__wrap .base-info .base-info__img:hover {
  cursor: pointer;
}

.derived-detail__wrap .base-info__content {
  padding: 20px;
}

.derived-detail__wrap .base-info__content div {
  margin-bottom: 16px;
  height: 24px;
  word-break: break-all;
}

.derived-detail__wrap .base-info__content span.title {
  display: inline-block;
  width: 80px;
  font-size: 14px;
  color: #999;
}

.base-info__content .grid-content.bg-purple {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.base-info__content .grid-content.bg-purple > span {
  line-height: 24px;
}

.base-info__content .grid-content.bg-purple > span:nth-child(even) {
  white-space: nowrap;
  display: inline-block;
  width: calc(100% - 80px);
  text-overflow: ellipsis;
  overflow: hidden;
}
