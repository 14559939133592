.sdw-req-cascader__wrap {
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}
.sdw-req-cascader__wrap:focus {
  outline: none;
}
.sdw-req-cascader__wrap {
  padding: 0 18px;
}

.sdw-req-cascader__icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  right: 6px;
  cursor: pointer;
  background-size: cover;
}

.sdw-req-cascader__drop-down {
  background-image: url('./img/down.png');
  background-repeat: no-repeat;
  transform-origin: center center;
  transition: transform .3s;
}

.sdw-req-cascader__drop-up {
  transform: rotate(-180deg);
}

.sdw-req-cascader__wrap.sdw-req-select__show-selected {
  border: 1px solid #265cf0;
}

.sdw-req-cascader__wrap.sdw-req-error-input {
  border: 1px solid #ff5e5e;
}

@keyframes changeCascaderHeight {
  from {
    height: 0;
  }
  to {
    height: 180px;
  }
}
.sdw-req-cascader-list-wrap{
  position: fixed;
  margin-top: 6px;
  z-index: 100000;
  overflow-y: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 14%);
  animation: changeCascaderHeight .2s;
}

.sdw-req-cascader-list-wrap .sdw-req-cascader-list__area-wrap {
  height: 180px;
  overflow: auto;
  float: left;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
  border: 1px solid #eee;
  background-color: #fff;
}

.sdw-req-cascader-list-wrap .sdw-req-cascader-list__area {
  position: relative;
  padding: 10px 30px 10px 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
}

.sdw-req-cascader-list-wrap .sdw-req-cascader-list__area:hover {
  background: #f5f7fa;
}

.sdw-req-cascader-list__area.is_selected {
  background: #f5f7fa;
  color: #265cf0;
  font-weight: 700;  
}


.sdw-req-cascader__list-item-right-icon {
  background: url(./img/icon_12_xl.png) no-repeat;
  display: inline-block;
  height: 14px;
  width: 14px;
  transform: rotate(-90deg);
  position: absolute;
  right: 8px;
  top:12px;
}

.sdw-req-cascader-list-wrap .sdw-req-cascader-list__empty {
  float: left;
  padding: 10px;
}

.sdw-req-error-input__tip {
  position: absolute;
  color: #ff5e5e;
  font-size: 12px;
  background-color: #fff;
  line-height: 14px;
  width: calc(100% + 40px);
}

.sdw-req-cascader-list__empty-item{
  padding: 0 0 0 10px;
  line-height: 40px;
  background-color: #eee;
}

.sdw-select-clearable {
  background-image: url('./img/clear.png');
  background-repeat: no-repeat;
}