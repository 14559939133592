.log-access-detail-main__body {
    padding: 4px 20px 20px 20px;
    position: relative;
    background: #fff;
    border: 1px solid #f6f6f6;
    border-radius: 4px 4px 0 0;
}

.log-access-detail-main__body_two {
    padding: 4px 20px 20px 20px;
    position: relative;
    background: #fff;
    border: 1px solid #f6f6f6;
    border-radius: 4px 4px 0 0;
    height: calc(100vh - 228px);
}

.log-access-detail-form {
    width: 800px;
    margin: 20px auto;
}

.log-access-detail-form .log-access-detail-title-main {
    width: 1500px;
    margin: 36px 0;
}

.log-access-detail-form .log-access-detail-form-button {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.log-access-detail-title-main .sdw__switch-button-wrap {
    top: 6px;
}

.log-access-detail-table-box .el-icon-plus,
.log-access-detail-table-box .el-icon-minus {
    cursor: pointer;
    color: rgb(38, 92, 240);
    padding: 5px;
    border: none;
    background: #eee;
    vertical-align: bottom;
    border-radius: 24px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    top: 20px;
    left: 210px;
}

.log-access-detail-table-box .el-icon-minus {
    margin-left: 45px;
    color: rgb(255, 94, 94) !important;
}

.log-access-detail-title-bar {
    display: inline-block;
    font-size: 18px;
    /*margin-bottom: 16px;*/
    padding-left: 8px;
    border-left: 2px solid #265cf0;
}

.log-access-detail-title-des {
    margin-left: 10px;
    color: #999;
    font-size: 12px;
}

.log-access-detail-box-main {
    display: flex;
    margin-bottom: 20px;
    line-height: 40px;
}

.log-access-detail-box-main .label {
    display: inline-block;
    width: 130px;
    text-align: right;
    line-height: 40px;
    margin-right: 12px;
}

.log-access-detail-box-main .el-icon-plus,
.log-access-detail-box-main .el-icon-minus {
    cursor: pointer;
    color: rgb(38, 92, 240);
    padding: 5px;
    border: none;
    background: #eee;
    vertical-align: bottom;
    border-radius: 24px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    margin-top: 9px;
}

.log-access-detail-box-main .el-icon-minus {
    margin-left: 45px;
    color: rgb(255, 94, 94) !important;
}

.log-access-detail-btn {
    margin: -14px 0 8px 0;
}

.log-access-detail-btn .pass {
    margin-left: 12px;
    background: #E6F9F5;
    border: 1px solid #83E6D2;
    border-radius: 2px;
    width: 40px;
    height: 22px;
    padding: 3px 8px;
    color: #01C39E;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    position: relative;
    top: 6px;
}

.log-access-detail-btn .nopass {
    margin-left: 12px;
    background: #FFEFEF;
    border: 1px solid #FFAFAF;
    border-radius: 2px;
    width: 40px;
    height: 22px;
    padding: 3px 8px;
    color: #FF5E5E;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    position: relative;
    top: 6px;
}

.log-access-detail-table-box {
    width: 1350px;
    margin: 10px auto;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
}

.log-access-detail-table-box table tbody.sdw-table__tbody {
    display: table-row-group;
}

.log-access-detail-table-box .emptyTextClass {
    display: none;
}

.log-access-detail-table-box .emptyTextClass span.text {
    display: none;
}


/* 步骤条 */
.log-access-step-container {
    width: 1500px;
    margin: 30px auto;
}

.log-access-detail-main__body .log-access-step-area,
.log-access-detail-main__body_two .log-access-step-area {
    display: table;
    width: 100%;
}

.log-access-detail-main__body .log-access-step-area > li,
.log-access-detail-main__body_two .log-access-step-area > li {
    display: table-cell;
}

/* 第一步 */
.log-access-detail-main__body .log-access-step-area .log-access-step-inner_1,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_1 {
    width: 700px;
    position: relative;
    font-size: 0;
    padding: 15px;
    text-align: center;
}

.log-access-detail-main__body .log-access-step-area .log-access-step-inner_1::before,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_1::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 2px;
    top: 28px;
    left: -275px;
    background: #eeeeee;
}

.log-access-detail-main__body .log-access-step-area li:first-child .log-access-step-inner_1::before,
.log-access-detail-main__body_two .log-access-step-area li:first-child .log-access-step-inner_1::before {
    background: none;
}

/* 圆圈数字 */
.log-access-detail-main__body .log-access-step-area .log-access-step-inner_1 .log-access-step-num_1,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_1 .log-access-step-num_1 {
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    vertical-align: middle;
    margin-right: 10px;
}

/* 圆圈数字：正在编辑状态 */
.log-access-detail-main__body .log-access-step-area .success .log-access-step-num_1,
.log-access-detail-main__body_two .log-access-step-area .success .log-access-step-num_1 {
    background: url('../img/one_editing_img@2x.png') no-repeat;
    background-size: cover;
}

/* 圆圈数字：未编辑状态 */
.log-access-detail-main__body .log-access-step-area .success ~ li .log-access-step-num_1,
.log-access-detail-main__body_two .log-access-step-area .success ~ li .log-access-step-num_1 {
    background: url('../img/two_notEdited_img@2x.png') no-repeat;
    background-size: cover;
}

/* 步骤标题 */
.log-access-detail-main__body .log-access-step-area .log-access-step-inner_1 span,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_1 span {
    vertical-align: middle;
    font-size: 18px;
}

/* 第二步 */
.log-access-detail-main__body .log-access-step-area .log-access-step-inner_2,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_2 {
    width: 700px;
    position: relative;
    font-size: 0;
    padding: 15px;
    text-align: center;
}

.log-access-detail-main__body .log-access-step-area .log-access-step-inner_2::before,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_2::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 2px;
    top: 28px;
    left: -275px;
    background: #265cf0;
}

.log-access-detail-main__body .log-access-step-area li:first-child .log-access-step-inner_2::before,
.log-access-detail-main__body_two .log-access-step-area li:first-child .log-access-step-inner_2::before {
    background: none;
}

/* 圆圈数字 */
.log-access-detail-main__body .log-access-step-area .log-access-step-inner_2 .log-access-step-num_2,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_2 .log-access-step-num_2 {
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    vertical-align: middle;
    margin-right: 10px;
}

/* 圆圈数字：已编辑状态 */
.log-access-detail-main__body .log-access-step-area li .log-access-step-num_2,
.log-access-detail-main__body_two .log-access-step-area li .log-access-step-num_2 {
    background: url('../img/one_edited_img@2x.png') no-repeat;
    background-size: cover;
}

/* 圆圈数字：正在编辑状态 */
.log-access-detail-main__body .log-access-step-area .success .log-access-step-num_2,
.log-access-detail-main__body_two .log-access-step-area .success .log-access-step-num_2 {
    background: url('../img/two_editing_img@2x.png') no-repeat;
    background-size: cover;
}

/* 步骤标题 */
.log-access-detail-main__body .log-access-step-area .log-access-step-inner_2 span,
.log-access-detail-main__body_two .log-access-step-area .log-access-step-inner_2 span {
    vertical-align: middle;
    font-size: 18px;
}



.dialog-del-visible .sdw-dialog-win__content-footer-button--primary {
    background-color: #ff5e5e !important;
    border-color: #ff5e5e !important;
}

.dialog-del-visible .sdw-dialog-win__content-footer-button--primary:hover {
    background: #ff7575 !important;
    border-color: #ff7575 !important;
    color: #fff !important;
}