.sdw-textarea-input__div-wrap {
  display: inline-block;
  position: relative;
  width: 100%;
}

.sdw-textarea-input__wrap {
  border: 1px solid #eee;
  border-radius: 4px;
  outline: none;
  padding: 8px 22px 8px 14px;
  min-height: 60px;
  box-sizing: border-box;
}

.sdw-textarea-input__wrap:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.sdw-textarea-input-error {
  border: 1px solid #ff5e5e;
}

.sdw-textarea-input-error__tip {
  position: absolute;
  color: #ff5e5e;
  font-size: 12px;
  background-color: #fff;
  line-height: 14px;
  bottom: -10px;
}

.sdw-textarea-input-clearable {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  /* 等视觉给好icon后调整位置 */
  top: 14px;
  right: 6px;
  cursor: pointer;
  background-image: url('./img/clear.png');
}

/* 鼠标聚焦边框颜色蓝起来 */
.sdw-textarea-input__on-focus .sdw-textarea-input__wrap {
  border: 1px solid #265cf0;
}

.sdw-textarea-input__wrap.sdw-error-input {
  border: 1px solid #ff5e5e;
}
