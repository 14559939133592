.add-application-win__label ~ .add-application-win__label {
  margin-top: 16px;
}

.add-application-win__label .label__title1 {
  display: inline-block;
  width: 84px;
  text-align: right;
  text-align:left;
  margin-right: 8px;
}
