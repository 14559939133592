.warning-title{
    width: 100%;
    height: 30px;
    color: rgba(0, 0, 0, 0.3);
    font-style: italic;
}
.modal {
    width: 100%;
    overflow-y: scroll;
}

