.sdw-radiobox__wrap {
  display: inline-block;
}

.sdw-radiobox__wrap > span {
  margin-right: 24px;
}

.sdw-radiobox__wrap > span input {
  position: relative;
  top: 1px;
}

.sdw-radiobox__wrap:hover {
  cursor: pointer;
}

.sdw-radiobox__wrap span.radiobox-text {
  margin-left: 4px;
}

.sdw-radiobox__wrap span.radiobox-text.current {
  color: #265cf0;
}
