.machFactory .page-search label {
    width: 80px;
    float: left;
    text-align: left;
    margin-right: 0px;
    font-size: 14px;
}

.machFactory .page-search .el-form-item__content {
    float: left;
    width: calc(100% - 80px);
}

.machFactory .page-search input {
    height: 40px;
    line-height: 40px;
}

.machFactory .page-search .el-form-item {
    margin-right: 40px;
}

.Derived .dot-status.enable:before {
    color: #265cf0;
}

.Derived .dot-status:before {
    content: "·";
    font-size: 40px;
    color: #ccc;
    vertical-align: top;
    padding-right: 3px;
}

.Derived .show-data-list {
    min-height: 300px;
    margin-top: 16px;
}

.btn-group .sdw-derived__more-button.batch button.el-button--default:hover {
    background: #eef2fe;
}

.batch.sdw-derived__more-button .el-icon-document::before {
    display: none;
}
.batch.sdw-derived__more-button .el-icon-document {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(./img/icon-16-creat@2x.png) no-repeat;
    background-size: cover;
    vertical-align: bottom;
}

.batch.sdw-derived__more-button:hover .el-icon-document {
    background: url(./img/icon-16-creat-hover@2x.png) no-repeat;
    background-size: cover;
}