.data-standard-detail__wrap > div {
  margin-bottom: 16px;
}

.data-standard-detail__wrap .base-info,
.data-standard-detail__wrap .standard-log {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}

.data-standard-detail__wrap .base-info__title {
  padding: 16px 20px;
  line-height: 28px;
  font-size: 18px;
  color: #262626;
  border-bottom: 1px solid #eee;
}

.data-standard-detail__wrap .base-info .base-info__img {
  margin-left: 8px;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
}

.data-standard-detail__wrap .base-info .base-info__img:hover {
  cursor: pointer;
}

.data-standard-detail__wrap .base-info__content {
  padding: 20px;
}

.data-standard-detail__wrap .base-info__content div {
  margin-bottom: 16px;
  height: 24px;
}

.data-standard-detail__wrap .base-info__content span.title {
  display: inline-block;
  width: 80px;
  font-size: 14px;
  color: #999;
}
.data-standard-detail__wrap .connect-table,
.data-standard-detail__wrap .standard-log {
  background-color: #fff;
  min-height: 300px;
}
