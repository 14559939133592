body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
}

.el-button--text {
  color: #265CF0 !important;
}

.el-button--text.is-disabled {
  color: #ccc !important;
}

.formInput span.label {
  color: #666 !important;
}

.el-switch.is-checked .el-switch__core {
  border-color: #265cf0 !important;
  background-color: #265cf0 !important;
}

.el-tabs__nav .el-tabs__item {
  color: #999;
}

.el-tabs__nav-scroll .el-tabs__nav {
  transform: none !important;
}

.el-tabs__item.is-active {
  /*border-bottom: 2px solid #265cf0 !important;*/
  color: #265cf0 !important;
}

.el-tabs__active-bar {
  background-color: #265cf0 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #265cf0 !important;
  background: #265cf0 !important;
}

.page-header {
  /* 导航设置box-shadow不生效问题 */
  position: relative;
  z-index: 99;
}

.el-dropdown {
  color: #262626 !important;
}

.el-collapse-item__wrap {
  background-color: #f7f9ff !important;
}

.el-pager li.active {
  border-color: #265cf0 !important;
  background-color: #265cf0 !important;
}

.border-radius-4px {
  border-radius: 4px;
  overflow: hidden;
}

.el-date-table td.available.today {
  color: #265cf0;
}

.el-date-table td.available.today.current {
  color: #fff;
}

.el-date-table td.available.in-range {
  background-color: #f6f6f6;
}

.el-date-range-picker.has-time {
  min-width: 520px !important;
  z-index: 10500 !important;
}

.el-time-panel {
  z-index: 10500 !important;
}

.el-date-table td {
  line-height: 32px;
}

.el-date-table th {
  display: inline-block;
  width: 22px;
  text-align: center;
}

.el-date-picker table {
  text-align: center;
}

.el-date-editor .el-input__inner,
.el-date-editor .el-input__inner:hover,
.el-date-picker__editor-wrap .el-input__inner,
.el-date-picker__editor-wrap .el-input__inner:hover {
  border: 1px solid #eee;
  padding: 0 18px;
}

.el-time-panel {
  width: 200px !important;
}

.el-date-picker__editor-wrap .el-input__inner:focus,
.el-date-range-picker__editor .el-input__inner:focus {
  border-color: #265cf0;
}

.el-time-panel__content.has-seconds::after,
.el-time-panel__content.has-seconds::before {
  background-color: #265cf0;
  margin-top: -20px;
}

li.el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #eee;
}

.el-picker-panel__footer .el-picker-panel__link-btn,
.el-time-panel__footer .el-time-panel__btn.confirm {
  color: #265cf0;
  font-weight: 800;
}

.el-date-table td.available.current:not(.disabled),
.el-date-table td.end-date,
.el-date-table td.start-date {
  position: relative;
  background-color: #265cf0 !important;
  border-radius: 50%;
}

.el-date-editor .el-input__inner:focus {
  border-color: #265cf0;
}

.el-date-table td.available.end-date,
.el-date-table td.available.start-date {
  position: relative;
  background-color: #265cf0 !important;
  border-radius: 50%;
  color: #fff;
}

.el-date-table td.available.end-date::after,
.el-date-table td.available.start-date::after {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  z-index: -1;
}

.el-date-table td.available.end-date::after {
  border-radius: 0 50% 50% 0;
}

.el-date-table td.available.start-date::after {
  border-radius: 50% 0 0 50%;
}

table.el-date-table tr.el-date-table__row {
  display: inline-flex;
  margin-top: 4px;
}

.el-date-editor .el-input__icon.el-icon-date::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
  background: url(../img/icon_data_picker@2x.png) no-repeat center;
  background-size: cover;
}

.page-mainbody {
  padding: 20px;
  position: relative;
  background: #fff;
  border: 1px solid #f6f6f6;
  border-radius: 4px 4px 0 0;
}

.operation-label-title {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
  padding: 0 12px;
  margin-right: -4px;
  background: #fff;
  border: 1px solid #eee;
  font-size: 14px;
  color: #262626;
  border-radius: 4px 0 0 4px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.is-disabled:hover {
  display: inline-block;
  color: #808080;
  cursor: not-allowed !important;
  background-color: transparent;
}

.el-button--primary {
  color: #fff !important;
  background-color: #265cf0 !important;
  border-color: #265cf0 !important;
}

.sdw-monitor-eye__wrap .sdw-monitor-eye__date-range-picker-wrap .el-input__inner {
  border: 1px solid #eee;
}

.el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-textarea__inner {
  border-color: #ff4949 !important;
}

.el-input__inner::placeholder  {
  color: #ccc !important;
}

.home-page .page-header__wrap, .home-page .page-main__wrap {
  width: 96% !important;
  min-width: 1366px;
}

.box-card .el-card__header {
  padding: 18px 20px;
  border-bottom: 1px solid #d1dbe5;
  box-sizing: border-box;
  height: 57px;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #EEEEEE !important;
}

.el-radio-button__inner {
  border-color:#EEEEEE !important;
  border-left: 1px solid #EEEEEE !important;
}

.topic-all-class__radio-group .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  box-shadow: 1px 0 0 0 #265cf0 !important;
}

.el-switch__core {
  background: #ccc !important;
}

.el-tabs__header,
.el-card__header,
.el-collapse-item__header {
  border-bottom: 1px solid #eee !important;
}

.el-collapse {
  border: 1px solid #eee !important;
}

.el-message-content {
  z-index: 1000000;
  position: relative;
}

.m-head,
.m-head__userInfo .user-name {
  height: 62px !important;
}

.menu__txt {
  /* height: 28px; */
}

.menu__item,
.menu__itemdisabled {
  line-height: 62px !important;
}

.m-select {
  line-height: 0;
}

.required::before {
  content: '*';
  width: 0;
  display: inline-block;
  color: #ff5e5e;
  position: relative;
  left: -10px;
}

.m-head > .m-head__logo > img {
  display: inline-block;
  height: 62px !important;
  width: 161px !important;
}

.m-head__userInfo .user-name {
  line-height: 62px !important;
}

button.el-dialog__headerbtn:focus .el-dialog__close,
button.el-dialog__headerbtn:hover .el-dialog__close {
  color: #255ff0;
}

.el-input .el-input__icon.el-icon-caret-top:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../img/icon_16_yjt@2x.png) no-repeat;
  background-size: cover;
}

.el-input__icon.el-icon-caret-bottom::before {
  display: none;
}
.el-input__icon.el-icon-caret-bottom {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(./../img/icon_12_xl.png) no-repeat;
  background-size: cover;
  right: 14px;
  top: 14px;
}

.el-cascader .el-icon-caret-bottom.el-input__icon.is-reverse {
  transform: rotateZ(-180deg);
}

.el-cascader-menu__item.el-cascader-menu__item--extensible::after {
  display: none;
}

.el-cascader-menu__item.el-cascader-menu__item--extensible::before {
  content: '';
  position: absolute;
  right: 8px;
  transform: rotate(-90deg);
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../img/icon_12_xl.png) no-repeat;
  background-size: cover;
}

/* element-tree css */
.el-tree.el-tree--highlight-current{
  border: none;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content,
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  color: #265cf0 !important;
  background: transparent !important;
}

.el-tree-node__content:hover,
.el-tree-node.is-current > .el-tree-node__content:hover {
  background: #e9eefd !important;
}

.el-tree-node__expand-icon.expanded,
span.el-tree-node__expand-icon {
  border-left-color: #999;
}

.el-select:hover .el-input .el-input__inner,
.el-select .el-input .el-input__inner {
  border: 1px solid #eee;
}

.el-date-editor.is-have-trigger.is-active.is-filled .el-input__inner,
.el-select .el-input .el-input__icon.el-icon-caret-top.is-reverse + .el-input__inner {
  border: 1px solid #265cf0;
}

/*
  一般的button，给类名 normal；确定按钮给 sure-button
*/
/* button 基础样式 */
button.normal,
button.sure-button {
  width: 96px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 16px;
}
button.normal:focus,
button.sure-button:focus {
  outline: none;
}
button.normal:hover,
button.sure-button:hover {
  cursor: pointer;
}
button.normal:hover,
button.sure-button:hover {
  color: #265cf0;
  border-color: #becefb;
  background-color: #e9effe;
}
button.normal:disabled,
button.sure-button:disabled {
  background: #f6f6f6;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #999;
  cursor: not-allowed;
}
/* 确定按钮样式 */
button.sure-button {
  color: #fff;
  background-color: #265cf0;
  border-color: #265cf0;
}
button.sure-button:disabled,
button.sure-button:disabled:hover {
  background-color: #a8bef9;
  border-color: #a8bef9;
}
button.sure-button:hover {
  color: #fff;
  background: #517df3;
  border-color: #517df3;
}
/* button---end */

/* 未开始 完成 通用样式 start */
.is_done,
.is_doing,
.is_error,
.is_new {
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
  border: 1px solid #eee;
  white-space: nowrap;
}

.is_doing,
.is_new {
  background-color: #f4f4f5;
  border-color: #e9e9eb;
  color: #909399;
}

.is_error {
  background-color: #f4f4f5;
  border-color: #d4468d;
  color: #c53541;
}

.is_done {
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a;
}
/* end */

.machFactory .page-main__wrap {
  min-width: 1280px;
}

.input-wrap {
  padding-right: 240px;
}

.input-wrap > span {
  display: inline-block;
}

.page-search {
  margin-bottom: 8px;
}

button.el-button+.el-button {
  margin-left: 12px;
}

.el-loading-spinner .el-loading-text {
  color: #265cf0 !important;
}

span.iconfont {
  font-size: 14px;
}

.sdw-monitor-eye__wrap .sdw-input__outer-div-wrap,
.page-search .input-wrap .sdw-input__outer-div-wrap {
  min-width: 250px;
}

/* drawer 中的form表单同意样式 --start */
.drawer__wrap-div {
  display: flex;
  margin-bottom: 16px;
}
.drawer__label {
  flex: 0 0 100px;
  line-height: 40px;
}
.drawer__main {
  flex: 1 1 auto;
}
/* drawer 中的form表单同意样式 --end */

.gray {
  color: #999;
}

.green {
  color: #01c39e;
}

.red {
  color: #ff5e5e;
}

.blue {
  color: #265CF0;
}

.icon-tip {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/tip@2x.png) no-repeat;
  background-size: cover;
  margin: 0 2px;
  position: relative;
  top: 3px;
  z-index: inherit;
}
