.add-application-win__label {
  padding-top: 8px;
}

.add-application-win__label span.title {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin: 16px 8px;
}

.add-application-win__label span.title::before {
  content: '';
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('../img/info@2x.png') no-repeat;
  background-size: cover;
  position: relative;
  top: 8px;
  left: -8px;
}

.add-application-win__label div.main {
  max-height: 488px;
  padding: 16px;
  background: #F6F6F6;
  line-height: 24px;
  overflow: auto;
}

.add-application-win__label .type-name {
  font-weight: 600;
}

.add-application-win__label .type-name ~ .type-name {
  margin-top: 16px;
}