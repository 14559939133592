.sdw-button-wrap__submit,
.sdw-button-wrap__cancel,
.sdw-button-wrap__outline
{
  display: inline-block;
  padding: 9px 24px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
}

.sdw-button-wrap__submit {
  border: 1px solid #ccc;
  color: #fff;
  background: #265cf0;
}

.sdw-button-wrap__cancel {
  border: 1px solid #ccc;
  color: #262626;
  background: #fff;
}

.sdw-button-wrap__outline {
  border: 1px solid #265cf0;
  color: #265cf0;
  background: #fff;
}

.sdw-button-wrap__submit:hover,
.sdw-button-wrap__cancel:hover,
.sdw-button-wrap__outline:hover
{
  cursor: pointer;
  background: rgba(38,92,240,0.08);
  border: 1px solid #265cf0;
  border-radius: 4px;
  color: #265cf0;
}

.sdw-button-wrap__submit.disabled:hover,
.sdw-button-wrap__cancel.disabled:hover,
.sdw-button-wrap__outline.disabled:hover,
.sdw-button-wrap__text.disabled:hover {
  cursor: not-allowed;
}

.sdw-button-wrap__submit.disabled {
  background-color: #a8bef9;
  border-color: #a8bef9;
  color: #fff;
}

.sdw-button-wrap__cancel.disabled {
  background-color: #f6f6f6;
  border-color: #ccc;
  color: #999;
}

.sdw-button-wrap__outline.disabled {
  background-color: #fff;
  border-color: #a8bef9;
  color: #a8bef9;
}

.sdw-button-wrap__text {
  display: inline-block;
  padding: 9px 8px;
  font-size: 14px;
  color: #265CF0;
}

.sdw-button-wrap__text:hover {
  cursor: pointer;
}

.sdw-button-wrap__text.disabled {
  border-color: #ccc;
  color: #999;
}

.sdw-button-wrap__normal {
  color: #265cf0;
}

.sdw-button-wrap__normal:hover {
  color: #265cf0;
  cursor: pointer;
}
