.monitor__user-search__outer-wrap {
  padding: 20px;
}

.monitor__user-search__base-info-wrap {
  display: flex;
}

.monitor__user-search__base-info-wrap .user-img {
  height: 84px;
  margin-right: 18px;
  border-radius: 4px;
}

.monitor__user-search__base-info-wrap .user-info {
  flex: 1 1 auto;
}

.monitor__user-search__base-info-wrap .sdw__col-wrap {
  line-height: 42px;
}

.monitor__user-search__base-info-wrap .label-title {
  display: inline-block;
  width: 87px;
  color: #999;
}

.monitor__user-search__outer-wrap .monitor__user-search__user-label-wrap {
  line-height: 54px;
  height: 34px;
  border-top: 1px solid #eee;
  margin-top: 20px;
}

.monitor__user-search__outer-wrap .monitor__user-search__user-label-wrap .user-label-title {
  color: #262626;
  font-weight: 600;
}

.monitor__user-search__outer-wrap .monitor__user-search__user-label-wrap .user-label-item {
  display: inline-block;
  height: 20px;
  font-size: 12px;
  color: #265cf0;
  line-height: 20px;
  margin-left: 16px;
  padding: 1px 8px;
  border: 1px solid #265cf0;
  border-radius: 4px;
  background-color: #eef2fe;
}