.page-search > .input-wrap > .ele-search-input {
  display: inline-block;
  margin: 0 8px 8px 0;
}

.el-input__icon.el-icon-search {
  cursor: pointer;
}

.sdw-data-standard__search-input-list-wrap {
  position: fixed;
  z-index: 99;
  width: 300px;
  height: 400px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
  overflow: scroll;
  margin-top: 8px;
}

.sdw-data-standard__search-input-list-wrap > div {
  padding: 10px;
  height: 20px;
  line-height: 20px;
}

.sdw-data-standard__search-input-list-wrap > div:hover {
  background-color: #f5f7fa;
  cursor: pointer;
}

.sdw-data-standard__search-input-list-wrap > div.selected {
  background-color: rgb(38, 92, 240);
}
