.sdw-dialog-win__wrap .el-input__inner {
  height: 40px;
}

.sdw-data-review-search-btn::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;
  background: url(../img/search_16_normal.svg) no-repeat;
  background-size: cover;
}

.sdw-data-review-search-btn:hover:before {
  background: url(../img/search_16_color.svg) no-repeat;
  background-size: cover;
}