.sdw-card__wrap {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  margin-top: 8px;
}

.sdw-card__wrap .card-header {
  font-size: 16px;
}

.sdw-card__wrap .sdw-card__lable-wrap {
  margin-top: 16px;
}

.sdw-card__wrap .sdw-card__lable {
  display: inline-block;
  width: 78px;
  margin-right: 16px;
  color: #999;
}

.sdw-card__wrap .sdw-card__lable-text {
  display: inline-block;
  width: calc(100% - 100px);
  word-break: break-word;
  vertical-align: text-top;
}
