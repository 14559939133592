.table-detail-base__opr-wrap {
  position: relative;
  margin: 8px 0 16px;
}

.table-detail-base__opr-add {
  position: absolute;
  right: 0;
}

.table-detail-base__left-base-info-warp {
  border-right: 1px solid #eee;
  width: 400px;
}