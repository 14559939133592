@keyframes changeCascaderHeight {
  from {
    height: 0;
  }
  to {
    height: 180px;
  }
}

.sdw-cascader__outer-div-wrap {
  display: inline-flex;
  position: relative;
}

.sdw-cascader__wrap {
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.sdw-cascader__wrap:focus {
  outline: none;
}

.sdw-cascader__wrap {
  padding: 0 18px;
}

.sdw-cascader__wrap.sdw-select__show-selected {
  border: 1px solid #265cf0;
}

.sdw-cascader__wrap.sdw-error-input {
  border: 1px solid #ff5e5e;
}

.sdw-error-input__tip {
  position: absolute;
  color: #ff5e5e;
  font-size: 12px;
  background-color: #fff;
  line-height: 14px;
  width: 100%;
  bottom: -16px;
}

.sdw-cascader__icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  /* 等视觉给好icon后调整位置 */
  top: 13px;
  right: 6px;
  cursor: pointer;
  background-size: cover;
}

.sdw-cascader__drop-down {
  background-image: url('./img/down.png');
  background-repeat: no-repeat;
  transform-origin: center center;
  transition: transform .3s;
}

.sdw-cascader__drop-up {
  transform: rotate(-180deg);
}

.sdw-cascader-clearable {
  background-image: url('./img/clear.png');
  background-repeat: no-repeat;
}

.sdw-cascader-list-wrap {
  position: absolute;
  margin-top: 42px;
  z-index: 100000;
  overflow-y: hidden;
  animation: changeCascaderHeight .3s;
  width: 100%;
  display: flex;
}

.sdw-cascader-list-wrap .sdw-cascader-list__area-wrap {
  height: 180px;
  float: left;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
  border: 1px solid #eee;
  background-color: #fff;
  overflow-y: overlay;
}

.sdw-cascader-list-wrap .sdw-cascader-list__empty {
  padding: 10px;
  width: 100%;
}

.sdw-cascader-list-wrap .sdw-cascader-list__area {
  position: relative;
  padding: 8px 30px 8px 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
}

.sdw-cascader-list-wrap .sdw-cascader-list__area:hover {
  background: #f5f7fa;
}

.sdw-cascader-list__area.is_selected {
  background: #f5f7fa;
  color: #265cf0;
  font-weight: 700;  
}

.sdw-cascader-list__area > span {
  position: absolute;
  top: 12px;
  right: 6px;
}

.sdw-cascader__list-item-right-icon {
  background: url(./img/icon_12_xl.png) no-repeat;
  display: inline-block;
  height: 14px;
  width: 14px;
  transform: rotate(-90deg);
  position: absolute;
  right: 8px;
}
