@keyframes changeDropDowmHeight {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 600px;
  }
}

.sdw__drop-down__menu-wrap {
  display: inline-block;
  position: relative;
  padding: 4px 12px 4px 4px;
}

.sdw__drop-down__menu-list {
  position: absolute;
  top: 30px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 14%);
  padding: 0 0 10px 0;
  overflow-y: auto;
  background-color: #fff;
  max-height: 600px;
  animation: changeDropDowmHeight .3s;
  z-index: 99;
}
.sdw__drop-down__menu-list::-webkit-scrollbar{
  width:0px;
}
.sdw__drop-down__menu-list::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #eee;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  top: -6px;
  left: 30px;
  border-bottom-color: #fff;
  border-right-color: #fff;
}

.sdw__drop-down__menu-list > div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin-top: 8px;
  padding: 4px 8px;
  /*min-width: 78px;*/
}

.sdw__drop-down__menu-list > div.current {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #265cf0;
}

.sdw__drop-down__menu-list > div:hover {
  background: #f5f7fa;
  color: #262626;
  height: 22px;
  cursor: pointer;
}

.sdw__drop-down__menu-title {
  display: inline-block;
  position: relative;
  padding-right: 24px;
}

.sdw__drop-down__menu-title::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(./img/down@2x.png) no-repeat;
  background-size: cover;
  position: absolute;
  top: 2px;
  right: 2px;
  transition: transform .3s;
}

.sdw__drop-down__menu-title.show_menu_list::after {
  transform: rotate(-180deg);
  transition: transform .3s;
}

.sdw__drop-down__menu-wrap .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
