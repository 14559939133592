.sdw-input3__outer-div-warp {
  display: inline-flex;
}

.sdw-input3__wrap {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0 22px 0 14px;
  box-sizing: border-box;
}

.sdw-input3__wrap:focus {
  outline: none;
}

.sdw-input3__wrap:disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* 鼠标聚焦边框颜色蓝起来 */
.sdw-input3__on-focus .sdw-input3-operation-label,
.sdw-input3__on-focus .sdw-input3__wrap {
  border: 1px solid #265cf0;
}

.sdw-input3-error__wrap .sdw-input3-operation-label {
  border: 1px solid #ff5e5e;
}

.sdw-input3-error {
  border: 1px solid #ff5e5e;
}

.sdw-input3-error__tip {
  position: absolute;
  color: #ff5e5e;
  font-size: 12px;
  background-color: #fff;
  line-height: 14px;
  width: 100%;
  bottom: -16px;
}

.sdw-input3-operation-label {
  display: inline-block;
  line-height: 38px;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  padding: 0 8px;
  position: relative;
  right: -4px;
  z-index: 1;
  color: #262626;
  border: 1px solid transparent;
  background-color: #fff;
}

.sdw-input3__div-wrap {
  display: inline-block;
  position: relative;
  line-height: 40px;
}

.sdw-input3-clearable {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 13px;
  right: 6px;
  cursor: pointer;
  background-image: url('./img/clear.png');
}
