.table-detail-index__header-wrap {
  height: 64px;
  line-height: 64px;
  background: #fff;
  font-size: 16px;
  margin-bottom: 16px;
  padding-left: 20px;
  border-radius: 4px;
}

.table-detail-index__tabs_wrap {
  background: #fff;
  border-radius: 4px;
}

.meta-data-updata-record__label {
  margin-top: 16px;
}

.meta-data-updata-record__label .label__title {
  display: inline-block;
  width: 98px;
}

.table-detail-index__header-wrap .style__label {
  color: #265cf0;
  border: 1px solid #265cf0;
  padding: 1px 8px;
  font-size: 12px;
  margin-right: 8px;
  border-radius: 4px;
}