.components-collapse-with-time-wrap {
  display: flex;
}

.components-collapse-with-time-wrap .left-wrap {
  flex: 0 0 110px;
  font-weight: 600;
  border-right: 1px solid #eee;
  position: relative;
  box-sizing: border-box;
  line-height: 54px;
}
.components-collapse-with-time-wrap .left-wrap::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #265CF0;
  position: absolute;
  right: -4px;
  top: 22px;
}

.components-collapse-with-time-wrap .right-wrap {
  flex: 1 1 auto;
  margin-left: 8px;
}