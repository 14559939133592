.page-main__wrap.data-access .data-access__main-wrap {
  width: 100%;
  min-height: 550px;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
}

.page-main__wrap.data-access .number-title__one {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url(../img/one_img@2x.png) no-repeat;
  background-size: cover;
  position: relative;
  top: 6px;
}

.page-main__wrap.data-access .number-title__two {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url(../img/two_img@2x.png) no-repeat;
  background-size: cover;
  position: relative;
  top: 6px;
}

.page-main__wrap.data-access .number-title__three {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url(../img/three_img@2x.png) no-repeat;
  background-size: cover;
  position: relative;
  top: 6px;
}

.page-main__wrap.data-access .config-title {
  font-size: 18px;
  color: #262626;
  margin-right: 64px;
}

.page-main__wrap.data-access .data-access__header .config-type {
  display: inline-block;
  padding: 9px 24px;
  border-radius: 4px;
  color: #fff;
  background-color: #265CF0;
  font-size: 14px;
  line-height: 22px;
}

.page-main__wrap.data-access .data-access__header .config-type:hover {
  cursor: pointer;
}

.page-main__wrap.data-access .data-access__header {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.page-main__wrap.data-access .data-access__body {
  line-height: 30px;
  position: relative;
}

.page-main__wrap.data-access .data-access__body .main-body {
  margin-left: 168px;
}

.page-main__wrap.data-access .data-access__body .number-title-wrap {
  display: inline-block;
  position: absolute;
}

.page-main__wrap.data-access .data-access__body .label-title-divider {
  display: inline-block;
  width: 2px;
  height: 18px;
  background-color: #265cf0;
  margin-top: 7px;
}

.page-main__wrap.data-access .data-access__body .label-title {
  margin-left: 8px;
  font-size: 18px;
  color: #262626;
}

.page-main__wrap.data-access .data-access__body .label-title-des {
  margin-left: 10px;
  color: #999;
  font-size: 12px;
}

.page-main__wrap.data-access .data-access__body .label-title-wrap {
  margin-top: 16px;
  display: flex;
}

.page-main__wrap.data-access .data-access__body .label {
  display: inline-block;
  width: 120px;
  text-align: right;
}

.page-main__wrap.data-access .data-access__footer {
  background-color: transparent;
  padding-left: 168px;
  margin-top: 16px;
}

.page-main__wrap.data-access .data-access__footer > span {
  display: inline-flex;
  padding: 9px 19px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 12px;
}

.page-main__wrap.data-access .data-access__footer > span:hover {
  cursor: pointer;
}

.page-main__wrap.data-access .data-access__body .add-config-rule-btn {
  display: inline-block;
  padding: 10px 16px;
  border: 1px #ccc dashed;
  border-radius: 2px;
  color: #999;
}

.page-main__wrap.data-access .data-access__body .add-config-rule-btn::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/add@2x.png) no-repeat;
  background-size: cover;
  vertical-align: text-top;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.page-main__wrap.data-access .data-access__body .add-config-rule-btn:hover {
  color: #265cf0;
  cursor: pointer;
  border-color: #265cf0;
}

.page-main__wrap.data-access .data-access__body .add-config-rule-btn:hover::before {
  background: url(../img/add_hover@2x.png) no-repeat;
  background-size: cover;
}

.page-main__wrap.data-access .icon-tip {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/tip@2x.png) no-repeat;
  background-size: cover;
  margin: 0 2px;
  position: relative;
  top: 3px;
}

.page-main__wrap.data-access .sdw-radiobox__wrap,
.page-main__wrap.data-access .data-access__body .label {
   line-height: 44px;
 }
