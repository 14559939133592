.sdw-tree-wrap_new .group-has-children_new,
.sdw-tree-wrap_new .group-has-no-children_new {
  position: relative;
  line-height: 36px;
  user-select: none;
  max-width: 88%;
}

.sdw-tree-wrap_new .group-has-children_new {
  display: inline-block;
  width: 88%;
}

.sdw-tree-wrap_new .group-has-children_new:hover,
.sdw-tree-wrap_new .group-has-no-children_new:hover {
  cursor: pointer;
  background: #e9eefd;
  border-radius: 4px;
}

.sdw-tree-wrap_new .group-has-children_new .sdw-tree__body-text::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(./img/tree_group@2x.png) no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.sdw-tree-wrap_new .group-has-children_new.expend .sdw-tree__body-text::before {
  transform: rotate(90deg);
}

.sdw-tree-wrap_new .group-has-children_new.current,
.sdw-tree-wrap_new .group-has-no-children_new.current {
  color: #265cf0;
}

.sdw-tree-wrap_new {
  height: 100%;
}

.sdw-tree__body-wrap {
  height: 88%;
  overflow: auto;
}

.sdw-tree__body-text {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sdw-tree__body-home_icon {
  position: absolute;
  width: 15px;
  height: 15px;
  margin-top: 11px;
  left: 11px;
  background: url(./img/tree_home@2x.png) no-repeat;
  background-size: cover;
}

.sdw-tree-wrap_new .group-has-no-children_new.current .sdw-tree__body-home_icon {
  position: absolute;
  width: 15px;
  height: 15px;
  margin-top: 11px;
  left: 11px;
  background: url(./img/tree_home_hover@2x.png) no-repeat;
  background-size: cover;
}

.sdw-tree__body-icon{
  display: none;
  height: 36px;
  margin-right: 10px;
}

.sdw-tree__body-icon img {
  cursor: pointer;
  height: 16px;
  margin: 10px 5px 10px 10px;
}

.group-has-children_new:hover .sdw-tree__body-icon,
.group-has-no-children_new:hover .sdw-tree__body-icon {
  display: block;
}