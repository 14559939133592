.bread-crumbs__page-breadcrumb__wrap {
  position: relative;
  width: 100%;
}

.bread-crumbs__breadcrumb {
  position: relative;
  height: 56px;
  font-size: 0;
  display: flex;
  align-items: center;
}

.bread-crumbs__breadcrumb .bread-crumbs__breadcrumb__item {
  display: inline-block;
  line-height: 22px;
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

.bread-crumbs__breadcrumb .arrow {
  display: inline-block;
  font-size: 12px;
  color: #999;
  line-height: 1;
  margin: 1px 4px 0;
}

.bread-crumbs__breadcrumb .bread-crumbs__breadcrumb__item.current {
  color: #262626;
  cursor: default;
}

.bread-crumbs__breadcrumb .bread-crumbs__breadcrumb__item {
  display: inline-block;
  line-height: 22px;
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

