.sdw-warn-strategy__wrap .el-icon-plus,
.sdw-warn-strategy__wrap .el-icon-minus {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}

.sdw-warn-strategy__wrap .el-icon-plus:hover,
.sdw-warn-strategy__wrap .el-icon-minus:hover {
  color: #265cf0;
}
.sdw-warn-strategy__wrap{
  width: 86%;
}

.sdw-warn-strategy__wrap.cycle_time .sdw-input__outer-div-wrap {
  height: 40px;
}