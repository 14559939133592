.sdw-dialog-win__wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, .4);
  z-index: 10010;
}

.sdw-dialog-win__content-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #262626;
  box-sizing: border-box;
}

.sdw-dialog-win__content-header {
  padding: 20px 20px 12px;
  font-size: 18px;
  position: relative;
}

.sdw-dialog-win__content-header .cancel-img  {
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(./img/cancel.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.sdw-dialog-win__content-header .cancel-img:hover {
  background-image: url(./img/cancel_hover.png);
}

.sdw-dialog-win__content-body {
  padding: 20px 40px 20px;
  overflow: overlay;
}

.sdw-dialog-win__content-footer {
  padding: 20px;
  text-align: right;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 0;
  right: 0;
}

.sdw-dialog-win__content-footer > button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #666;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 0 0 8px;
}

.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--primary {
  color: #FFF;
  background-color: #265CF0;
  border-color: #265CF0;
}

.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--primary:hover {
  background: rgba(38,92,240,0.08);
  border-color: #265CF0;
  color: #265CF0;
}

.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--cancel:hover {
  color: #265CF0;
  border-color: #becefb;
  background-color: #e9effe;
}

.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--primary:disabled,
.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--cancel:disabled {
  background-color: #f6f6f6;
  border-color: #ccc;
  color: #999;
}

.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--primary:disabled:hover,
.sdw-dialog-win__content-footer >
button.sdw-dialog-win__content-footer-button--cancel:disabled:hover {
  cursor: not-allowed;
}


/* 弹窗中的select需要单独设置样式 */
.sdw-dialog-win__wrap .sdw-select-list-wrap {
  /* 弹窗中的select需要传入 listwidth */
  position: fixed;
}
