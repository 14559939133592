.sdw-edit_mid_table__center-wrap .mid_table_body .left-box .stepList_title {
    width: 350px;
    height: 58px;
    line-height: 58px;
    color: #262626;
    border-bottom: 2px solid #D8DCE5;
}
.sdw-edit_mid_table__center-wrap .mid_table_body .left-box .stepList_content{
    width: 350px;
    height: 550px;
    overflow:inherit;
}
.sdw-edit_mid_table__center-wrap .mid_table_body .left-box .stepList_content::-webkit-scrollbar {
    display: none;
}
.sdw-edit_mid_table__center-wrap .mid_table_body .left-box .stepList_content .stepList_content_li{
    margin-top: 10px;
    height: 32px;
    color: #262626;
    background: #EAEAEA;
    line-height: 32px;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
