@keyframes rotateInfinity {
  0% {
    transform: rotate(0deg); 
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadding-main {
  position: relative;
}

.loadding-main-wrap {
  min-height: 200px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .7);
  color: #265cf0;
  z-index: 9999;
}

.loadding-main-wrap__body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

.loadding-main .loadding-main-img {
  display: inline-block;
  overflow: hidden;
  margin: auto 0;
}

.loadding-main .loadding-main-img img {
  width: 33px;
  height: 33px;
  animation: rotateInfinity 1s linear infinite;
}

.loadding-main .loadding-main-tip {
  display: block;
}