.sdw__switch-button-wrap {
  display: inline-block;
  width: 46px;
  height: 24px;
  border-radius: 12px;
  background-color: #bfcbd9;
  position: relative;
  top: 8px;
}

.sdw__switch-button-wrap:hover {
  cursor: pointer;
}

.sdw__switch-button-wrap::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left .3s;
}

.sdw__switch-button-wrap.is_true {
  background-color: #265cf0;
}

.sdw__switch-button-wrap.is_true::before {
  position: absolute;
  top: 2px;
  left: 24px;
  transition: left .3s;
}