.sdw-form {
  width: 100%;
}


.sdw-form-item{
  margin-bottom: 22px;
}


.sdw-form-label-left .sdw-form-item__label {
  text-align: left;
}

.sdw-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 12px 11px 0;
  box-sizing: border-box;
}

.sdw-form-item.is-required .sdw-form-item__label:before {
  content: '*';
  color: #ff4949;
  margin-right: 4px;
}

.sdw-form-item__content {
  line-height: 42px;
  position: relative;
  font-size: 14px;
}

.sdw-input {
  position: relative;
  font-size: 14px;
}
.sdw-input, .sdw-input__inner {
  width: 100%;
  display: inline-block;
}
.sdw-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #bfcbd9;
  box-sizing: border-box;
  color: #1f2d3d;
  font-size: inherit;
  height: 36px;
  line-height: 1;
  outline: 0;
  padding: 3px 10px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

/* 表单校验标红border */
.sdw-form-item.is-error .sdw-input__wrap,
.sdw-form-item.is-error .comp__date-time-picker__wrap,
.sdw-form-item.is-error .sdw-textarea-input__wrap,
.sdw-form-item.is-error .sdw-select-input__wrap {
  border-color: #ff4949;
}

.sdw-form-item__error {
  color: #ff4949;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}