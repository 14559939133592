.el-date-editor.el-date-editor--daterange.el-input {
  width: 350px;
}

.sdw-re-run-win__checkbox-wrap {
  margin: 16px 0 0 40px;
}

.sdw-re-run-win__date-range-picker-wrap input.el-input__inner {
  width: 346px;
}

.sdw-re-run-win__date-range-picker-wrap .sdw-re-run-win__checkBox {
  width: inherit;
  line-height: 40px;
  vertical-align: middle;
  display: flex;
}
/*.sdw-re-run-win__date-range-picker-wrap .sdw-re-run-win__checkBox .sdw-checkbox__wrap{*/
/*  display: flex;*/
/*}*/
.Application  .page-main__wrap {
  min-height: 100px;
  width: 100% !important;
}

.sdw-re-run-win__date-range-picker-wrap .sdw-select-list-wrap.is-show-to-up {
  top: -96px;
}

.api-rerun__checkbox-wrap {
  margin-top: 14px;
  /*white-space: nowrap;*/
  max-width: 200px;
}

.api-rerun__checkbox-wrap .el-checkbox {
  display: block;
  margin: 10px 2px;
}

.api-rerun__checkbox-wrap .el-checkbox__label {
  padding-left: 8px;
}

.api-rerun__checkbox-wrap input[type='checkbox'] {
  width: 0;
  height: 0;
}

.api-rerun__checkbox-wrap .el-checkbox {
  color: #000000;
}
