.data-editor-win-wrap span.label {
  display: inline-block;
  width: 100px;
  float: left;
  line-height: 40px;
}

.data-editor-win-wrap span.label.required::before {
  content: '*';
  width: 0;
  display: inline-block;
  color: #FF5E5E;
  position: relative;
  left: -10px;
}

.data-editor-win-wrap > div {
  margin-bottom: 18px;
}

