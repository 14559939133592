.operationconfiguration-flex-left-right{
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: 350px;
}
.operationconfiguration-left-nav{
  width:285px;
  height:auto;
}
.operationconfiguration-right-content{
  width: 100%;
  /* height:100%; */
  background-color: #fff;
}

.operationconfiguration-left-nav-content{
  width:230px;
  height:100%;
  background-color: #fff;
}
.operationconfiguration-left-nav-item{
  line-height: 30px;
  padding: 10px 0px 10px 15px;
  background-color: #fff;
  cursor: pointer;
}
/* .operationconfiguration-left-nav-item:hover{
  background-color: #ddd;
} */

.operationconfiguration-left-nav-checkitem{
  background-color: #a8bef9;
  color: #fff;
}