.enable-comp__title-label__is-effect::before,
.enable-comp__title-label__no-effect::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
  margin-right: 4px;
}

.enable-comp__title-label__is-effect::before {
  background: #265cf0;
}