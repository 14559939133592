/* 抽屉表单样式 */
.logAccess-win_warp span.label {
    display: inline-block;
    width: 100px;
    float: left;
    text-align: left;
    line-height: 40px;
}

.logAccess-win_warp span.label.required::before {
    content: '*';
    width: 0;
    display: inline-block;
    color: #FF5E5E;
    position: relative;
    left: -10px;
}

.logAccess-win_warp > div {
    margin-bottom: 18px;
}

.logAccess-win_warp .content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 111px;
    position: relative;
    display: block;
}

.logAccess-win_warp .content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 100px;
    position: relative;
}

.logAccess-win_warp .el-icon-plus,
.logAccess-win_warp .el-icon-minus {
    cursor: pointer;
    color: rgb(38, 92, 240);
    padding: 5px;
    border: none;
    background: #eee;
    vertical-align: bottom;
    border-radius: 24px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    margin-top: 9px;

}
.logAccess-win_warp .el-icon-minus {
    margin-left: 45px;
    color: rgb(255, 94, 94) !important;
}

.info-reason_wrap {
    background: #fbfbfb;
    border-radius: 4px;
    padding: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    max-height: 380px;
    overflow: auto;
}

.detailed-win_dialog .sdw-dialog-win__content-body {
    height: 500px !important;
}

/* 列表页状态提示样式 */
.status-comp_is-effect::before,
.status-comp_no-effect::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ff5e5e;
    margin-right: 4px;
}

.installStatus-comp_is-effect::before,
.enable-comp_is-effect::before,
.installStatus-comp_no-effect::before,
.enable-comp_no-effect::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ccc;
    margin-right: 4px;
}

.installStatus-comp_is-effect::before,
.enable-comp_is-effect::before,
.status-comp_is-effect::before {
    background: #265cf0;
}


/* 链路检测弹窗样式 */
.status-win-label {
    display: flex;
    justify-content: space-between;
}

.status-win__check-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #265CF0;
    margin: 0 0 20px 10px;
}

.status-win__success-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #01C39E;
    margin: 0 0 20px 10px;
}

.status-win-img_check {
    display: inline-block;
    margin-right: 8px;
    height: 20px;
    vertical-align: text-bottom;
    animation: rotateInfinity 1s linear infinite;
}

.status-win-img {
    display: inline-block;
    margin-right: 8px;
    height: 20px;
    vertical-align: text-bottom;
}

.status-win__error-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FF5E5E;
    margin: 0 0 20px 10px;
}

.status-win-wrap {
    width: 450px;
    height: 250px;
    background: #FBFBFB;
    border-radius: 4px;
}

.status-win-wrap .status-win-text {
    display: inline-block;
    width: 200px;
    line-height: 22px;
    font-size: 14px;
    color: #666666;
    margin: 16px 0 0 16px;
}

.status-win-wrap .status-win__check-result {
    font-size: 12px;
    color: #999999;
    vertical-align: middle;
    margin: 16px 16px 16px 0;
}

.status-win-wrap .status-win__success-result {
    font-size: 12px;
    color: #01C39E;
    vertical-align: middle;
    margin: 16px 16px 16px 0;
}
.status-win-wrap .status-win__check-result {
    font-size: 12px;
    color: #999999;
    vertical-align: middle;
    margin: 16px 16px 16px 0;
}
.status-win-wrap .status-win__error-result {
    font-size: 12px;
    color: #FF5E5E;
    vertical-align: middle;
    margin: 16px 16px 16px 0;
}

.detailed-win_table .ant-table-thead > tr > th {
    color: #999999 !important;
    background: #eef2fe !important;
    font-weight: 400;
}
