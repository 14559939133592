.sdw-business-indicators__wrap{
  margin-bottom: 8px;
}
.sdw-business-indicators-search__wrap{
  margin-right: 8px;
}

.sdw-business-indicators-search__wrap.btn-group{
  position: absolute;
  right: 20px;
  top: 20px;
}
.sdw-business-indicators-search__wrap.btn-group button {
  width: 98px;
  height: 40px;
  font-size: 14px;
  background: #265cf0;
  border-radius: 4px;
  border: none;
  color: #fff;
  outline: none;
}
.sdw-business-indicators-search__wrap .sure-button.el-icon-plus:before {
  padding-right: 4px;
}
