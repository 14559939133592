/* 通用样式 导航左右结构 --start */
.sdw__bread-crumbs__wrap {
  display: flex;
  margin: 16px 0 8px 0;
}

.sdw__bread-crumbs__wrap .sdw__bread-crumbs__left-wrap {
  flex: 1 1 auto;
}

.sdw__bread-crumbs__wrap .sdw__bread-crumbs__left-wrap .sdw-select-input-span__wrap,
.sdw__bread-crumbs__wrap .sdw__bread-crumbs__left-wrap .sdw-input__outer-div-wrap,
.sdw__bread-crumbs__wrap .sdw__bread-crumbs__left-wrap .comp__date-time-picker__wrap {
  margin: 0 8px 8px 0;
}

.sdw__bread-crumbs__wrap .sdw__bread-crumbs__left-wrap .sdw-select-input-span__wrap {
  display: inline-block;
}

.sdw__bread-crumbs__wrap .sdw__bread-crumbs__right-wrap {
  flex: none;
}

.sdw__bread-crumbs__wrap .sdw__bread-crumbs__right-wrap > span {
  margin: 0 8px 8px 0;
}

.sdw__table__wrap {
  margin-bottom: 16px;
}
/* 通用样式 导航左右结构 --end */
