.subject-domain-detail__dropdown-menu_wrap {
  padding: 28px 25px;
  margin-bottom: 16px;
  background-color: #fff;
  background-image: url(./img/title-bg@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.panel-title__txt:hover {
  color: #265cf0;
}

.views-management__no-domain-wrap {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.page-main__wrap .el-card {
  border: none;
  box-shadow: none;
  border-radius: 4px;
}
