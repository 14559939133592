img.monitor__user-search__search-img {
  position: relative;
  left: 50%;
  top: 279px;
  transform: translateX(-50%) translateY(-50%);
}

.monitor__user-search__body-wrap {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 4px;
}
#userTrace {
  /*position: relative !important;*/
  /*display: inline-block;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*top: 0;*/
  /*left: 0;*/
}
