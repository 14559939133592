.detail-blood__dialod-wrap {
  padding: 16px;
  position: absolute;
  width: 330px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 12px 0 rgb(0, 0, 0, 0.06);
  z-index: 999;
}

.detail-blood__flow-chart-wrap {
  padding: 5px 12px;
  border-radius: 4px;
  border: 1px solid #eee;
}

.detail-blood__flow-chart-wrap.type__dataset,
.detail-blood__flow-chart-wrap.type__app {
  background-color: #E4F6FF;
  border: 1px solid #49C2FC;
}

.detail-blood__flow-chart-wrap.type__dataset:hover,
.detail-blood__flow-chart-wrap.type__app:hover {
  background-color: #C9EDFF;
}

.detail-blood__flow-chart-wrap.type__atom {
  background-color: #EEEFF1;
  border: 1px solid #939EB5;
}

.detail-blood__flow-chart-wrap.type__atom:hover {
  background-color: #DEE1E8;
}

.detail-blood__flow-chart-wrap.type__current {
  background-color: #E6F9F6;
  border: 1px solid #01C39E;
}

.detail-blood__flow-chart-wrap.type__current:hover {
  background-color: #CDF3EC;
}

.detail-blood__flow-chart-wrap.type__3,
.detail-blood__flow-chart-wrap.type__1,
.detail-blood__flow-chart-wrap.type__2 {
  background-color: #E9EEFD;
  border: 1px solid #265CF0;
}

.detail-blood__flow-chart-wrap.type__3:hover,
.detail-blood__flow-chart-wrap.type__1:hover,
.detail-blood__flow-chart-wrap.type__2:hover {
  background-color: #D4DFFC;
}

.detail-blood__flow-chart__label {
  margin-left: 4px;
  font-size: 14px;
  color: #262626;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 84%;
  display: inline-block;
}

.detail-blood__flow-chart__label-img {
  width: 16px;
  height: 16px;
}

.detail-blood__dialod-wrap .label-wrap {
  display: flex;
}

.detail-blood__dialod-wrap .label {
  color: #999;
  display: inline-block;
  flex: 0 0 78px;
  height: 28px;
  line-height: 28px;
  min-width: 78px;
  margin-right: 4px;
}

.detail-blood__dialod-wrap .label-nowrap {
  display: inline-block;
  white-space: nowrap;
  width: 210px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 24px;
}

.detail-blood__dialod-wrap .label-break-all {
  display: inline-block;
  width: 210px;
  line-height: 28px;
  word-break: break-all;
}

.detail-blood__flow-chart__obj-wrap-div {
  display: block;
  color: #999;
  line-height: 24px;
  width: 146px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-blood__wrap-box {
  overflow: overlay;
  position: relative;
  min-height: 760px;
  background: url('./img/dot-bg.png');
  margin: 20px;
  box-sizing: border-box;
}

.detail-blood__wrap-box .sdw-empty__wrap {
  transform: translateY(200px);
}