.sdw-input__outer-div-wrap,
.sdw-input__div-wrap {
  display: inline-flex;
  position: relative;
}

.sdw-input__outer-div-wrap {
  min-width: 150px;
}

.sdw-input__wrap,
.sdw-textarea-input__wrap {
  border: 1px solid #eee;
  border-radius: 4px;
}

.sdw-input__wrap:focus,
.sdw-textarea-input__wrap {
  outline: none;
}

.sdw-textarea-input__wrap {
  padding: 10px 18px 0;
  min-height: 60px;
}

.sdw-input__wrap {
  padding: 0 22px 0 14px;
}

.sdw-input__wrap:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.sdw-error-input {
  border: 1px solid #ff5e5e;
  border-radius: 4px;
}

.sdw-error-input__tip {
  position: absolute;
  color: #ff5e5e;
  font-size: 12px;
  background-color: transparent;
  line-height: 14px;
  width: 100%;
  bottom: -16px;
}

.sdw-input-clearable {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  /* 等视觉给好icon后调整位置 */
  top: 14px;
  right: 6px;
  cursor: pointer;
  background-image: url('./img/clear.png');
}

/* 鼠标聚焦边框颜色蓝起来 */
.sdw-input__on-focus .operation-label-title,
.sdw-input__on-focus .sdw-input__wrap,
.sdw-input__on-focus .sdw-textarea-input__wrap,
.sdw-input__on-focus .sdw-multiple-input__wrap {
  border: 1px solid #265cf0;
}

.operation-label-title {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
  padding: 0 12px;
  margin-right: -4px;
  background: #fff;
  border: 1px solid #eee;
  font-size: 14px;
  color: #262626;
  border-radius: 4px 0 0 4px;
}

.sdw-error-input__wrap .operation-label-title {
  border: 1px solid #ff5e5e;
}

.sdw-multiple-input__wrap {
  display: inline-block;
  border-radius: 4px;
  width: calc(100% + 36px);
  border: 1px solid #eee;
}

.sdw-multiple-input__wrap-error {
  display: inline-block;
  border-radius: 4px;
  width: calc(100% + 36px);
  border: 1px solid #ff5e5e;
}

.sdw-input__multiple-choice-div-wrap {
  width: 100%;
  height: 180px;
  overflow-y: auto;
  position: absolute;
  border: 1px solid #eee;
  z-index: 9;
  background-color: #fff;
}

.sdw-input__multiple-choice-item {
  padding: 0 10px;
  line-height: 36px;
}

.sdw-input__multiple-choice-item:hover {
  background-color: #f5f7fa;
}

.sdw-input__multiple-disabled-item{
  color: #999;
  padding: 0 10px;
  line-height: 36px;
}

.sdw-input__multiple-disabled-item:hover{
  cursor: not-allowed;
}

.sdw-input-tags.tag {
  display: inline-block;
  background-color: #f0f2f5;
  color: #909399;
  border-radius: 4px;
  padding: 8px;
  margin: 4px 0 4px 8px;
  line-height: 14px;
  border: 1px solid #eee;
}

.sdw-input-tags.search-input,
.sdw-input-tags.search-input:hover {
  border: none;
  outline: none;
}

.sdw-input-tags__clear-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(./img/cancel.png) no-repeat;
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
}

.sdw-input-tags:hover {
  border: 1px solid #265cf0;
}

