@keyframes changeDatePickerHeight {
    from {
        height: 0;
    }
    to {
        height: 340px;
    }
}

.comp__date-picker__out-div {
    position: relative;
    display: inline-block;
}

.comp__date-picker__date-page-wrap {
    position: absolute;
    top: 56px;
    height: 344px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #aaa;
    z-index: 9;
    background-color: #fff;
    overflow: hidden;
    animation: changeDatePickerHeight 0.3s;
}

.comp__date-picker__input-wrap {
    display: inline-block;
}

.comp__date-picker__input,
.comp__date-picker__input:focus {
    display: inline-block;
    height: 40px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    outline: none;
}

.comp__date-picker__input-error {
    display: inline-block;
    height: 40px;
    background: #fff;
    border: 1px solid #ff5e5e;
    border-radius: 4px;
    outline: none;
}

.comp__date-picker__input-wrap {
    position: relative;
    width: 273px;
    border-color: transparent;
}

.comp__date-picker__input,
.comp__date-picker__input-error {
    padding: 0 0 0 18px;
    display: inline-block;
    width: 255px;
    font-size: 14px;
}

.comp__date-picker__tip {
    position: absolute;
    color: #ff5e5e;
    font-size: 12px;
    background-color: transparent;
    line-height: 14px;
    width: 100%;
    bottom: -16px;
}

.comp__date-picker__input.light-color {
    border-color: #265cf0;
}

.comp__date-picker__input-wrap::after,
.comp__date-picker__input-clear-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 8px;
    top: 14px;
}

.comp__date-picker__input-wrap:not(:hover):after {
    content: '';
    background: url(./img/icon_data_picker@2x.png) no-repeat;
    background-size: cover;
}

.comp__date-picker__input-clear-icon {
    background: url(./img/clear@2x.png) no-repeat;
    background-size: cover;
}

.comp__date-picker__input-clear-icon:hover {
    cursor: pointer;
}

.comp__date-picker__date-range-wrap {
    position: absolute;
    width: 324px;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.comp__date-picker__date-range-wrap.second {
    left: 340px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #eee;
}

.comp__date-picker__date-range-wrap.second::before {
    display: none;
}

.comp__date-picker__date-range-wrap::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid #eee;
    transform: rotate(45deg);
    background-color: #fff;
    position: absolute;
    top: -6px;
    left: 30px;
    border-bottom-color: #fff;
    border-right-color: #fff;
}

.comp__date-picker__date-range-title {
    position: relative;
    line-height: 40px;
}

.comp__date-picker__date-range-title > span {
    display: inline-block;
    text-align: center;
    user-select: none;
}

.comp__date-picker__date-range-year {
    width: 260px;
}

.comp__date-picker__date-range-year-month {
    width: 190px;
}

.comp__date-picker__date-range-year > span:hover,
.comp__date-picker__date-range-year-month > span:hover {
    color: #265cf0;
    cursor: pointer;
}

.comp__date-picker__date-range-btn {
    width: 24px;
    cursor: pointer;
    margin: 0 4px;
}

.comp__date-picker__date-range-btn:hover {
    color: #265cf0;
}

.comp__date-picker__date-range-btn.left-year-btn::before {
    content: '';
    display: inline-block;
    height: 16px;
    width: 16px;
    background: url(./img/icon_12_right@2x.png) no-repeat;
    background-size: cover;
    transform: rotate(180deg);
    vertical-align: middle;
}

.comp__date-picker__date-range-btn.right-year-btn::before {
    content: '';
    display: inline-block;
    height: 16px;
    width: 16px;
    background: url(./img/icon_12_right@2x.png) no-repeat;
    background-size: cover;
    vertical-align: middle;
}

.comp__date-picker__date-range-btn.right-month-btn::before {
    content: '';
    display: inline-block;
    height: 14px;
    width: 14px;
    background: url(./img/icon_16_yjt@2x.png) no-repeat;
    background-size: cover;
    transform: rotate(90deg);
    vertical-align: middle;
}

.comp__date-picker__date-range-btn.left-month-btn::before {
    content: '';
    display: inline-block;
    height: 14px;
    width: 14px;
    background: url(./img/icon_16_yjt@2x.png) no-repeat;
    background-size: cover;
    transform: rotate(-90deg);
    vertical-align: middle;
}

.comp__date-picker__date-range-week {
    border-bottom: 1px solid #eee;
    line-height: 36px;
}
.comp__date-picker__date-range-week > span,
.comp__date-picker__date-range-day-btn-wrap {
    display: inline-block;
    width: 40px;
    height: 34px;
    padding: 0 2px;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    position: relative;
}

.comp__date-picker__date-range-day {
    height: 40px;
    line-height: 40px;
}

.comp__date-picker__date-range-day-btn {
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: auto;
}

.comp__date-picker__date-range-day-btn:hover {
    background-color: #eee;
}

.comp__date-picker__date-range-day-btn.gray {
    color: #c0c4cc;
}

.comp__date-picker__date-range-day-btn.today {
    color: #265cf0;
    font-weight: 700;
}

.comp__date-picker__date-range-day-btn.current {
    background-color: #265cf0;
    color: #fff;
}

.comp__date-picker__ten-years-wrap {
    margin-top: 16px;
    border-top: 1px solid #eee;
}

.comp__date-picker__ten-years-item {
    display: inline-block;
    width: 25%;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.comp__date-picker__ten-years-item:hover {
    cursor: pointer;
    color: #265cf0;
}

.comp__date-picker__date-range-day-btn.disabled,
.comp__date-picker__date-range-day-btn-wrap.disabled {
    cursor: not-allowed;
    color: #999;
    background-color: #eee;
}

.comp__date-picker__date-range-day-btn-wrap.range__in {
    background-color: #eee;
}

.comp__date-picker__date-range-day-btn-wrap.current.max__date::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #eee;
    border-radius: 0 50% 50% 0;
    position: relative;
    left: -2px;
}

.comp__date-picker__date-range-day-btn-wrap.current.min__date::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #eee;
    border-radius: 50% 0 0 50%;
    position: relative;
    left: 2px;
}

.comp__date-picker__input-label {
    display: inline-block;
    border: 1px solid #eee;
    line-height: 40px;
    padding: 0 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: relative;
    right: -4px;
    z-index: 9;
    background-color: #fff;
    vertical-align: bottom;
}

.comp__date-picker__input-label.light-color {
    border-color: #265cf0;
}
