.label-main__body {
    padding: 4px 20px 20px 20px;
    position: relative;
    background: #fff;
    border: 1px solid #f6f6f6;
    border-radius: 4px 4px 0 0;
}

.user-label-form {
    width: 700px;
    margin: 20px auto;
}

.label-title-main {
    width: 700px;
    margin: 30px 0;
}

.label-box-main {
    display: flex;
    margin-bottom: 20px;
    line-height: 40px;
}

.label-btn_box {
    margin: -14px 0 8px 0;
}

.label-btn_img {
    margin: 0 -10px 4px 2px;
    display: inline-block;
    height: 20px;
    vertical-align: middle;
}

.label-btn_img:hover {
    cursor: pointer;
}

.label-btn_info {
    display: inline-block;
}

.label-box-main .label {
    display: inline-block;
    width: 130px;
    text-align: right;
    line-height: 40px;
    margin-right: 12px;
}

.label-box-main .label_1 {
    display: inline-block;
    width: 130px;
    text-align: right;
    line-height: 60px;
    margin-right: 12px;
}

.label-group-wrap {
    padding: 8px;
    box-sizing: border-box;
    background-color: #f4f7fe;
    border-radius: 4px;
}

.btn_box {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.label-info__wrap {
    height: 40px;
    line-height: 40px;
    background: #fff;
    font-size: 16px;
    border-radius: 4px;
}

.label-btn_box .label-info__label {
    color: #666666;
    border: 1px solid #cccccc;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 4px;
    background-color: #f6f6f6;
    margin-right: 8px;
}

.label-btn_box .label-info__success_label {
    color: #01c39e;
    border: 1px solid #83e5d2;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 4px;
    background-color: #e6f9f5;
    margin-right: 8px;
}

.label-btn_box .label-info__error_label {
    color: #FF5E5E;
    border: 1px solid #FFAFAF;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 4px;
    background-color: #FFEFEF;
    margin-right: 8px;
}

/*表格*/
.label-table {
    width: 700px;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
}

.label-table table tbody.sdw-table__tbody {
    display: table-row-group;
}

.label-table .emptyTextClass {
    display: none;
}

.label-table .emptyTextClass span.text {
    display: none;
}

.label-title-bar {
    font-size: 18px;
    margin-bottom: 16px;
    padding-left: 8px;
    border-left: 2px solid #265cf0;
}

.label-title-des {
    margin-left: 10px;
    color: #999;
    font-size: 12px;
}


/* 步骤条 */
[class^="container"] {
    width: 1500px;
    margin: 30px auto;
}

.step-area {
    display: table;
    width: 100%;
}

.step-area > li {
    display: table-cell;
}

/* 第一步 */
.step-area .step-inner_1 {
    position: relative;
    font-size: 0;
    padding: 15px;
    text-align: center;
}

.step-area .step-inner_1::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 2px;
    top: 28px;
    left: -285px;
    background: #eeeeee;
}

.step-area li:first-child .step-inner_1::before {
    background: none;
}

/* 圆圈数字 */
.step-area .step-inner_1 .step-num_1 {
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    vertical-align: middle;
    margin-right: 10px;
}

/* 圆圈数字：正在编辑状态 */
.step-area .success .step-num_1 {
    background: url('../img/one_editing_img@2x.png') no-repeat;
    background-size: cover;
}

/* 圆圈数字：未编辑状态 */
.step-area .success ~ li .step-num_1 {
    background: url('../img/two_notEdited_img@2x.png') no-repeat;
    background-size: cover;
}

/* 步骤标题 */
.step-area .step-inner_1 span {
    vertical-align: middle;
    font-size: 18px;
}

/* 第二步 */
.step-area .step-inner_2 {
    position: relative;
    font-size: 0;
    padding: 15px;
    text-align: center;
}

.step-area .step-inner_2::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 2px;
    top: 28px;
    left: -285px;
    background: #265cf0;
}

.step-area li:first-child .step-inner_2::before {
    background: none;
}

/* 圆圈数字 */
.step-area .step-inner_2 .step-num_2 {
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    vertical-align: middle;
    margin-right: 10px;
}

/* 圆圈数字：已编辑状态 */
.step-area li .step-num_2 {
    background: url('../img/one_edited_img@2x.png') no-repeat;
    background-size: cover;
}

/* 圆圈数字：正在编辑状态 */
.step-area .success .step-num_2 {
    background: url('../img/two_editing_img@2x.png') no-repeat;
    background-size: cover;
}

/* 步骤标题 */
.step-area .step-inner_2 span {
    vertical-align: middle;
    font-size: 18px;
}
