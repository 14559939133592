/* button样式需要的直接复制下面的即可，不要全局引用，会影响到table中的button，需要最前面加限制class */
/* button 基础样式 */
.sdw-application-detail__wrap button {
  width: 96px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 16px;
}
.sdw-application-detail__wrap button:focus {
  outline: none;
}
.sdw-application-detail__wrap button:hover {
  cursor: pointer;
}
.sdw-application-detail__wrap button:hover {
  color: #265cf0;
  border-color: #becefb;
  background-color: #e9effe;
}
.sdw-application-detail__wrap button:disabled {
  background: #f6f6f6;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #999;
  cursor: not-allowed;
}
/* 确定按钮样式 */
.sdw-application-detail__wrap button.sure-button {
  color: #fff;
  background-color: #265cf0;
  border-color: #265cf0;
}
.sdw-application-detail__wrap button.sure-button:disabled,
.sdw-application-detail__wrap button.sure-button:disabled:hover {
  background-color: #a8bef9;
  border-color: #a8bef9;
}
.sdw-application-detail__wrap button.sure-button:hover {
  background: rgba(38,92,240,0.08);
  border-color: #265CF0;
  color: #265CF0;
}
/* button---end */


.sdw-application-detail__title-wrap {
  line-height: 60px;
  padding: 0 20px;
  background-color: #fff;
  position: relative;
}

.sdw-application-detail__title-wrap__right {
  position: absolute;
  right: 20px;
}

.sdw-application-detail__wrap .el-button--text:hover {
  cursor: pointer;
}

.sdw-api-detail__center-wrap {
  min-height: 640px;
  width: 100%;
  /*margin-top: 16px;*/
  background-color: #fff;
  display: flex;
  position: relative;
}

.sdw-api-detail__center-wrap .left-box {
  flex: 0 0 300px;
  padding: 20px;
  border: 1px solid #eee;
}

.sdw-api-detail__center-wrap .center-box {
  flex: 0 0 300px;
  padding: 20px;
  border: 1px solid #eee;
}

.sdw-api-detail__center-wrap .left-box .title,
.sdw-api-detail__center-wrap .center-box .title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.sdw-api-detail__center-wrap .right-box {
  flex: 1;
  border: 1px solid #eee;
  position: relative;
  width: calc(100% - 724px);
  box-sizing: border-box;
  overflow: overlay;
}

.sdw-application-detail__opration-buttom-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-top: 1px solid #eee;
  background-color: #fff;
}

.sdw-application-detail__opration {
  position: absolute;
  right: 20px;
  bottom: 0;
  line-height: 80px;
}

.sdw-application-detail__title-wrap__right .publish {
  background: url(../img/publish@2x.png) no-repeat 18px 13px;
  background-size: 12px;
  padding-left: 16px;
}

.sdw-application-detail__title-wrap__right .publish:hover {
  background: url(../img/publish_hover@2x.png) no-repeat 18px 13px;
  background-size: 12px;
  padding-left: 16px;
}

.sdw-application-detail__title-wrap__right .publish:disabled {
  background: url(../img/publish_disabled@2x.png) no-repeat 18px 13px;
  background-size: 12px;
  padding-left: 16px;
}

.label-box ~ .label-box {
  margin-top: 8px;
}
.current_target{
  height: 550px;
}
.label-box__title {
  width: 100%;
  padding-left: 12px;
  box-sizing: border-box;
  line-height: 28px;
  opacity: 0.74;
  background: #f7f9ff;
  border: 1px solid #eeeeee;
  border-radius: 4px 4px 0 0;
}

.label-box__main {
  width: 100%;
  max-width: 302px;
  max-height: 150px;
  overflow-y: auto;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 0 0 4px 4px;
  position: relative;
}

.label-box__button_add{
  line-height: 60px;
  text-align: right;
  height: 60px;
}

.application-detail__checkbox-wrap {
  margin: 14px 12px;
  white-space: nowrap;
  max-width: 300px;
}

.sdw-application-detail__opration-wrap {
  margin-bottom: 16px;
  position: relative;
}

.sdw-application-detail__opration-wrap span.right-wrap {
  position: absolute;
  right: 16px;
}

.sdw-application-detail__wrap .el-tabs__item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
}

.sdw-application-detail__tree-is-edit {
  position: relative;
  padding-right: 20px;
}
.sdw-application-detail__tree-is-edit::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/edited@2x.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 10px;
}
