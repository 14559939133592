.sdw-edit_mid_table__center-wrap {
    min-height: 640px;
    width: 100%;
    margin-top: 16px;
    position: relative;
    /*background: #FFFFFF;*/
}
.sdw-edit_mid_table__wrap .el-button--text:hover {
    cursor: pointer;
}
.sdw-edit_mid_table__center-wrap .sdw-edit_mid_table__center-wrap-body-title{
    background: #FFFFFF;
    height: 25px;
    padding: 10px 18px;
}
.sdw-edit_mid_table__center-wrap .mid_table_view {
    padding: 0px 18px;
    height: 200px;
    background: #FFFFFF;
}
.sdw-edit_mid_table__center-wrap .mid_table_body {
    margin-top: 10px;
    display: flex;
}
.sdw-edit_mid_table__center-wrap .mid_table_body .left-box {
    flex: 0 0 350px;
    padding: 0px 18px;
    height: 620px;
    background: #FFFFFF;
    overflow-y: scroll;
}
.sdw-edit_mid_table__center-wrap .mid_table_body .right-box {
    flex: 1 1;
    padding: 0px 18px;
    margin-left: 10px;
    background: #FFFFFF;
}
.sdw-edit_mid_table__center-wrap-body-title .sdw-edit_mid_table__center-wrap-body-title-word{
    width: 98px;
    color: #262626;
    margin-right: 11px;
}
.sdw-edit_mid_table__center-wrap-body-title .sdw-edit_mid_table__center-wrap-body-title-button{
    cursor: pointer;
    padding: 4px 7px;
    background: #FFFFFF;
    width: 80px;
    font-size: 10px;
    line-height: 22px;
    color: #265CF0;
    border: 1px solid #265CF0;
    border-radius: 4px;
}
.sdw-edit_mid_table__center-wrap .center_job_type_1{
    height: 155px;
}
.center_job_type_1 {
    margin-top: 20px;
}
.center_job_type_1 .formInput {
    height: 135px;
}
.center_job_type_1 .label{
    display: inline-block;
    width: 85px;
    float: left;
    margin-left: 28px;
}
.center_job_type_1 .label .sdw-input__outer-div-wrap {
    height: 100px;
}
.center_job_type_1 .label1{
    display: inline-block;
    width: 95px;
    height: 40px;
    float: left;
}
.sdw-editmid__title-wrap__right {
    position: absolute;
    right: 20px;
}

.sdw-editmid__title-wrap__right .publish {
    background: url(../img/publish@2x.png) no-repeat;
    background-position: 18px 13px;
    background-size: 12px;
    padding-left: 16px;
}

.sdw-editmid__title-wrap__right .publish:hover {
    background: url(../img/publish_hover@2x.png) no-repeat;
    background-position: 18px 13px;
    background-size: 12px;
    padding-left: 16px;
}

.sdw-editmid__title-wrap__right .publish:disabled {
    background: url(../img/publish_disabled@2x.png) no-repeat;
    background-position: 18px 13px;
    background-size: 12px;
    padding-left: 16px;
}

.sdw-editmid__title-wrap__right .stop {
    border: 1px solid #F06326;
    color: #F06326;
}
