.sdw-config-rule-wrap {
  padding: 8px 24px;
  background-color: #f7f9ff;
  width: 980px;
  margin-bottom: 16px;
}

.sdw-config-rule-del-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(../img/del@2x.png) no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.sdw-config-rule-del-icon:hover {
  background: url(../img/del_hover@2x.png) no-repeat;
  background-size: cover;
  cursor: pointer;
}
