.data-search__search-export__wrap {
  display: flex;
  width: 100%;
  max-height: 1120px;
}

.data-search__search-export__wrap-left{
  background-color: #fff;
  box-sizing: border-box;
  flex: 0 0 320px;
  margin-right: 2px;
}

.data-search__search-export__wrap-right {
  background-color: #fff;
  box-sizing: border-box;
}

.data-search__search-export__wrap-right {
  flex: 1 1 auto;
}

.data-search__search-export__wrap-left__drop-menu {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  margin: 20px 20px 12px 20px;
}

.data-search__search-export__wrap-left__drop-menu > div {
  display: inline-block;
}

.data-search__search-export__wrap-left__select-num {
  display: inline-block;
  position: absolute;
  right: 0;
  line-height: 30px;
  color: #999;
}

.data-search__search-export__wrap-left__select-item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 8px;
  max-width: 300px;
  display: flex;
}

.data-search__search-export__wrap-left__select-item>div{
  width: 244px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height:40px;
  /*flex-grow:1;*/
  line-height:40px;
}

.data-search__search-export__wrap-left__select-item:hover{
  background-color: #F7F9FF;
}

.data-search__search-export__wrap-left__select-item .data-search__search-export__del_icon{
  display: none;
  width: 16px;
  line-height: 48px;
  height: 40px
}

.data-search__search-export__wrap-left__select-item:hover .data-search__search-export__del_icon{
  display: block;
}

.data-search__search-export__main {
  position: relative;
  margin-top: 16px;
  min-height: 600px;
  overflow: auto;
}

.data-search__search-export__text {
  position: absolute;
  text-align: center;
  width: 100%;
  color: #999;
  font-size: 14px;
  top: 50%;
}

.data-search__search-export__wrap-right {
  display: table-row-group;
  line-height: 40px;
  width: 100%;
  overflow: overlay;
}

.data-search__search-export__wrap-right .export-opr-wrap {
  /*position: relative;*/
  color: #999999;
  padding: 0 20px;
  /*display: flex;*/
}

.data-search__search-export__wrap-right .export-opr-table {
  padding: 0 30px 0 20px;
}

.data-search__search-export__wrap-right .export-opr-table .table-box .emptyTextClass {
  top: 5%;
}

.data-search__search-export__wrap-right .opr-wrap-select {
  display: flex;
  height: 50px;
  border-bottom: 2px solid #EEEEEE;
  margin-top: 6px;
}

.data-search__search-export__wrap-right .opr-wrap-select .opr-left-wrap {
  margin-left: 20px;
  flex: 1 1 auto;
  line-height: 50px;
  font-size: 16px;
}

.data-search__search-export__wrap-right .opr-wrap-select .opr-left-wrap>div{
  display: inline-block;
  height: 20px;
  line-height: 20px;
}

.data-search__search-export__wrap-right .opr-wrap-select .opr-right-wrap {
  flex: 0 0 auto;
  margin-right: 20px;
  display: flex;
  margin-top: 2px;
  background-color: #EEEEEE;
  border: 2px solid #EEEEEE;
  border-radius: 4px;
  height: 32px;
  padding: 2px;
}

.data-search__search-export__wrap-right .opr-right-wrap .sdw-button-wrap__submit {
  width: 80px;
  height: 20px;
  line-height: 20px;
}

.data-search__search-export__wrap-right .opr-wrap-select .opr-right-wrap .opr-right-wrap-li {
  height: 31px;
  width: 102px;
  color: #666666;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  border-radius: 2px;
  cursor: pointer;
}

.data-search__search-export__wrap-right .opr-wrap-select .opr-right-wrap .opr-right-wrap-li.selected-clicked {
  background: #FFFFFF;
  color: #265CF0;
  transition: all .3s;
}

.data-search__search-export__wrap-right .opr-wrap-select .opr-right-wrap .opr-right-wrap-li.disable-clicked {
  cursor: not-allowed;
}

.data-search__search-export__wrap-right .opr-wrap {
  padding: 20px 20px 0 20px;
  display: flex;
}

.data-search__search-export__wrap-right .opr-wrap .label-text {
  width: 70px;
  text-align: left;
  color: #666666;
}

.data-search__search-export__wrap-right .opr-wrap .opr-left-wrap {
  flex: 1 1 auto;
}

.data-search__search-export__wrap-right .opr-wrap .opr-left-wrap .opr-list-wrap {
  /*display: flex;*/
}

.data-search__search-export__wrap-right .opr-wrap .el-icon-plus,
.data-search__search-export__wrap-right .opr-wrap .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px;
  border: none;
  background: #eee;
  vertical-align: bottom;
  border-radius: 24px;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  margin: 0 0 18px 10px;
}

.data-search__search-export__wrap-right .opr-wrap .el-icon-minus {
  margin-left: 8px;
  color: rgb(255, 94, 94) !important;
}

.data-search__search-export__wrap-right .opr-wrap .opr-right-wrap {
  flex: 0 0 120px;
}

.data-search__search-export__wrap-right .plus-or-minus-btn:hover {
  color: #265CF0;
  cursor: pointer;
}

.data-search__search-export__wrap-right .opr-list-wrap {
  display: inline-block;
  margin-bottom: 8px;
}

.data-search__search-export__wrap-right .opr-list-wrap .sdw-select-input-span__wrap {
  margin-right: 8px;
}
