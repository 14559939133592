.derived__rerun-win-wrap .el-icon-plus,
.derived__rerun-win-wrap .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px;
  border: none;
  background: #eee;
  vertical-align: middle;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  margin-left: 8px;
}

.derived__rerun-win-wrap .el-icon-minus {
  color: rgb(255, 94, 94)
}

