.selected-attributes-body .el-dropdown-menu {
    margin: 5px 0;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06) !important;
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    padding: 6px 0;
    z-index: 10;
    position: absolute;
    top: 30px !important;
    left: 22px !important;
    min-width: 105px;
}

.selected-attributes-body .el-dropdown-menu {
    min-width: 150px;
}

.selected-attributes-body .el-dropdown-menu__item:hover {
    color: #262626 !important;
    background: #f5f7fa !important;
}

.selected-attributes-body .el-button--text {
    border: none;
    color: #265CF0 !important;
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
}

.selected-attributes-body .el-button--text.is-disabled {
    border: none;
    color: #ccc !important;
}

.selected-attributes-body .el-button--text:hover {
    background: none;
    cursor: pointer;
}

.selected-attributes-body .sdw-select-list-wrap-up {
    height: 200px;
}

.selected-attributes-body .edit {
    width: 122px;
    height: 40px;
    color: #fff;
    border: 1px solid #ccc;
    background-size: 14px !important;
    background: #265cf0 url(../img/icon_edit@2x.png) no-repeat 22px 12px;
    padding-left: 18px;
}

.selected-attributes-body .edit:hover {
    width: 122px;
    height: 40px;
    color: #265cf0;
    border: 1px solid #265cf0;
    background-size: 14px !important;
    background: rgba(38,92,240,0.08) url(../img/icon_edit_hover@2x.png) no-repeat 22px 12px;
    padding-left: 18px;
}

.selected-attributes-body .edit:disabled {
    width: 122px;
    height: 40px;
    color: #fff;
    border-color: #a8bef9;
    background-size: 14px !important;
    background: #a8bef9 url(../img/icon_edit@2x.png) no-repeat 22px 12px;
    padding-left: 18px;
}

.selected-attributes-body .rerun {
    color: #262626;
    border: 1px solid #cccccc;
    background-size: 14px !important;
    background: #fff url(../img/icon_rerun@2x.png) no-repeat 22px 12px;
    padding-left: 18px;
}

.selected-attributes-body .rerun:hover {
    color: #265cf0;
    border: 1px solid #265cf0;
    background-size: 14px !important;
    background: rgba(38,92,240,0.08) url(../img/icon_rerun_hover@2x.png) no-repeat 22px 12px;
    padding-left: 18px;
}

.selected-attributes-body .rerun:disabled {
    color: #a8bef9;
    border-color: #a8bef9;
    background-size: 14px !important;
    background: #fff url(../img/icon_rerun_disabled@2x.png) no-repeat 22px 12px;
    padding-left: 18px;
}
