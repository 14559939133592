.columns_filed_id{
    width: 24px;
    height: 14px;
    line-height: 14px;
    font-size: 8px;
    border-radius: 4px;
    text-align: center;
    color: #265CF0;
    border: 1px solid #265CF0;
}
.table-list-checkout{
    height: 58px;
    line-height: 58px;
    border-bottom: 2px solid #D8DCE5;
}
.table-list-table_content{
    display: flex;
}
.table-list-table_content_left {
    flex: 0 0 450px;
    height: 550px;
    overflow: overlay;
    padding-right: 18px;
    border-right: 2px solid #D8DCE5;
}
.table-list-table_content_right {
    height: 550px;
    flex: 1 1;
    padding: 0px 18px;
    overflow: overlay;
    width: 900px;
}
.table-list-table_content_left-title{
    background: #FFFFFF;
    height: 40px;
    line-height: 40px;
}
.table-list-table_content_left-words{
    width: 56px;
    height: 20px;
    color: #262626;
    margin-right: 11px;
}
.table-list-table_content_left-title-button{
    cursor: pointer;
    padding: 4px 7px;
    background: #FFFFFF;
    width: 66px;
    font-size: 10px;
    line-height: 20px;
    color: #265CF0;
    border: 1px solid #265CF0;
    border-radius: 4px;
}
.table-list-table_content_right-title{
    background: #FFFFFF;
    height: 40px;
    line-height: 40px;
}
.table-list-table_content_right-words{
    width: 180px;
    height: 20px;
    color: #262626;
    margin-right: 8px;
}
.table-list-table_content_right-title-button{
    cursor: pointer;
    padding: 4px 7px;
    background: #FFFFFF;
    width: 40px;
    font-size: 10px;
    line-height: 20px;
    color: #265CF0;
    border: 1px solid #265CF0;
    border-radius: 4px;
}
.table-list-table_content_right-title-button-loading{
    padding: 4px 7px;
    background: #FFFFFF;
    width: 40px;
    font-size: 10px;
    line-height: 20px;
    border: 1px solid #d1dbe5;
    border-radius: 4px;
    color: #bbb;
    cursor: not-allowed;
}
