.application-setting-field-row-wrap{
  margin-top: 12px;
  background-color: #f9faff;
  padding: 18px 20px 18px 10px;
}
.application-setting-field-row{
  display: flex;
  align-items: center;
}
.application-setting-field-bgc{
  margin-top: 15px;
}
.application-setting-field-bgc .sdw-select-input__wrap{
  background-color: #fff;
}

.application-setting-field-delete{
  font-size: 24px!important;
  color: #cecece;
  cursor: pointer;
  margin-left: 15px;
}
.application-setting-field-delete:hover{
  color: #3063f1;
}
.application-setting-field-add-icon{
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.application-setting-field-add{
  width: 105px;
  padding: 10px;
  margin-top:15px;
  color: #999;
  border-radius: 2px;
  border: 1px dotted #cccccc;
  cursor: pointer;
}
.application-setting-field-add:hover{
 color: #265cf0;
 border: 1px dotted #265cf0;
}

.application-setting-field-margin-sty{
  margin-left: 15px;
}

.application-setting-field-title{
  position: relative;
  font-size: 16px;
  padding-left: 10px;
}
.application-setting-field-title::before{
  position: absolute;
  top: 2px;
  left: 0px;
  content: '';
  display: inline-block;
  width: 2px;
  height: 18px;
  background: #265CF0;
}