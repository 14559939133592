.monitor__user-search__outer-wrap {
  padding: 20px;
}

.monitor__user-search__service__link-account-wrap {
  display: flex;
}

.monitor__user-search__service__link-account-wrap .label {
  flex: 0 0 70px;
  margin-right: 16px;
  line-height: 34px;
  font-size: 16px;
}

.monitor__user-search__service__desc-wrap {
  box-sizing: border-box;
  display: flex;
  background-color: rgba(38,92,240,0.08);
  padding: 12px 12px 12px 16px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 0 8px 8px 0;
}
.monitor__user-search__service__desc-wrap::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  display: inline-block;
  width: 4px;
  padding: 0px !important;
  height: 100%;
  background-color: #265CF0;
  padding: 12px;
  box-sizing: border-box;
}

.monitor__user-search__service__desc-wrap .title {
  flex: 0 0 80px;
  margin-right: 16px;
  color: #999;
}

.monitor__user-search__service__desc-wrap .desc {
  flex: 1 1 auto;
}

.monitor__user-search__service__desc-wrap .desc .service__traject-info__item-wrap {
  line-height: 22px;
  color: #262626;
  font-size: 14px;
}

.monitor__user-search__service__work-order__status-wrap::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 8px;
}
.monitor__user-search__service__work-order__status-wrap.blue::before {
  background-color: #265cf0;
}

.monitor__user-search__service__work-order__more-click {
  text-align: center;
  color: #265CF0;
  margin-top: 16px;
}

.monitor__user-search__service__work-order__more-click .btn {
  cursor: pointer;
}

.blue-text {
  color: #265CF0;
}

.service__traject-info__item-wrap {
  display: flex;
}

.service__traject-info__item-wrap .left-label {
  flex: 0 0 80px;
}

.monitor__user-search__outer-wrap .gray {
  margin-right: 4px;
}