.monitor-management__send-record__member-wrap {
  display: inline-block;
  min-height: 40px;
  line-height: 40px;
  min-width: 760px;
  width: 100%;
  background-color: #fafafa;
  color: #999;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0 8px;
  box-sizing: border-box;
  white-space: normal;
  word-break: break-all;
}