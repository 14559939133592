.views-management__content-wrap {
  display: flex;
  flex-wrap: wrap;
}

.views-management__content-wrap .m-panel-theme {
  min-width: 346px;
  width: 15.8%;
  height: auto;
  margin: 0 10px 20px 10px;
}

.views-management__wrap {
  position: relative;
  min-height: 200px;
}

.views-management__wrap:hover {
  box-shadow: 0 2px 12px 0 rgba(38, 38, 38, 0.1);
}

.views-management__content-wrap .target__row {
  color: #666;
  line-height: 22px;
}

.views-management__wrap .views-management__add-new {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.views-management__wrap .views-management__add-img {
  text-align: center;
}

.views-management__wrap .views-management__add-img img {
  display: inline-block;
  width: 56px;
  height: 56px;
}

.views-management__wrap .views-management__add-button {
  margin-top: 10px;
  text-align: center;
}

.views-management__wrap:hover {
  cursor: pointer;
}

.el-dialog .el-dialog__title,
.el-dialog .el-form-item__label,
.el-dialog .el-dialog__body {
  color: #262626;
}

.el-button.el-button--default {
  color: #666;
}

.domain-management__dialog-wrap .el-dialog__body {
  padding: 30px 20px 0;
  margin-bottom: 0;
}

.domain-management__dialog-wrap .el-dialog__close,
.domain-management__dialog-wrap .el-dialog__close:hover {
  color: #696969;
  transform: scale(0.8);
}

.domain-management__dialog-wrap .el-dialog__headerbtn:focus .el-dialog__close, 
.domain-management__dialog-wrap .el-dialog__headerbtn:hover .el-dialog__close {
  color: #696969;
}
