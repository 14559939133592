.table-box {
  width: 100%;
  font-size: 14px;
  position: relative;
  table-layout: fixed;
}

.table-box .table-box-table {
  width: 100%;
  border-bottom: none;
  background-color: #fff;
  position: relative;
}

.table-box tr.sdw-table__tr {
  border-bottom: 1px solid #ebeef5;
}

.table-box tr.sdw-table__tr:nth-child(odd) {
  background-color: #fff;
}

.table-box tr.sdw-table__tr:nth-child(even) {
  background-color: #fbfbfb;
}

.table-box .table-box-table .table-box-table-thead th{
  color: #999999;
  padding: 15px 20px;
  text-align: left;
  background-color: #eef2fe;
  word-break: break-all;
  line-height: 20px;
}

.table-box .table-box-table .table-box-table-thead th.checkInput,
.table-box .table-box-table td.checkInput {
  width: 20px;
}

.table-box .table-box-table td{
  height: 28px;
  padding: 10px 20px;
  font-size: 14px;
  color: #262626;
  word-break:break-all;
  position: relative;
  text-align: left;
}

.table-box .table-box-table td .tdContent > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.table-box .table-box-table td .tdContentTip{
  cursor: pointer;
}

.table-box .emptyTextClass {
  width: 136px;
  height: 136px;
  position: absolute;
  top: 20%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.table-box .emptyTextClass {
  background-image: url(./img/empty@2x.png);
  background-size: cover;
}

.table-box .emptyTextClass span.text {
  position: absolute;
  bottom: -18px;
  text-align: center;
  width: 100%;
  color: #999;
  font-size: 14px;
  display: block;
}

/* 给table的body设置定高 */
table tbody.sdw-table__tbody,
table.table-box-table thead,
table.table-box-table tbody tr.sdw-table__tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table thead th {
  background: #ccc;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #265cf0 !important;
  border-color: #265cf0 !important;
}

.table-box .el-checkbox__input.is-checked .el-checkbox__inner:after {
  transform: rotate(45deg) scaleY(1);
}
.table-box .el-checkbox__input.is-checked .el-checkbox__inner:after {
  transform: rotate(45deg) scaleY(1);
}
.table-box .el-checkbox__inner:after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform .15s ease-in .05s;
  transform-origin: center;
}

.table-box .el-checkbox, .el-checkbox-button__inner, .el-radio {
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.table-box .el-checkbox {
  color: #666;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
}
.table-box .el-checkbox, .el-checkbox__input {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.table-box .el-checkbox__input {
  cursor: pointer;
  outline: 0;
  line-height: 1;
  vertical-align: middle;
}

.table-box .el-checkbox, .el-checkbox__input {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.table-box .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
}

.btn-group .batch .el-button--default {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #666;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
  padding-right: 90px;
}

.btn-group .batch .el-button--default:hover {
  color: #265cf0;
  border-color: #becefb;
  background-color: #e9effe;
  background: #FFFFFF;
  border: 1px solid #265CF0;
}

.reRunData button{
  height: 40px;
  width: 98px;
  margin-right: 10px;
  box-sizing: border-box;
  line-height: 0 !important;
}

.reRunData {
  position: relative;
  top: -36px;
  display: inline-block;
}

.el-button-span .is-disabled {
  color: #fff !important;
  background-color: #93aef8 !important;
  border-color: #93aef8 !important;
}

.table-box .sdw-table__tbody .sdw-table__tr .tdWhiteSpace {
  overflow: hidden;
}

.table-box .sdw-table__tbody .sdw-table__tr .tdWhiteSpace .tdContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 100%;
}

.table-box .sdw-table__tbody .sdw-table__tr .tdWhiteSpace .tdContent > div {
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-box .sdw-table__tbody .sdw-table__tr button {
  font-size: 14px !important;
}

.table-box .tdTip:before{
  content: "";
  width: 0px;
  height: 0px;
  border-top: 10px solid #262626;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  bottom: -7px;
  left: 5px;
}

.table-box .fiexdClass {
  position: sticky !important;
  right: 0;
  box-shadow: -7px 0 15px -2px rgb(0 0 0 / 10%);
}

.table-box .fiexdTdClass {
  position: sticky !important;
  right: 0;
  box-shadow: -7px 0 15px -2px rgb(0 0 0 / 10%);
  background-color: inherit;
}

.table-box table {
  border-spacing: 0
}

.table-box .sdw__table-th-dividing-line {
  display: inline-block;
  width: 3px;
  height: 32px;
  border-right: 1px solid #ccc;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: col-resize;
}

.table-box .el-checkbox__original {
  display: none;
}

.table-box .sdw-table__td-has-children {
  margin-right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(./img/right@2x.png) no-repeat;
  background-size: cover;
  vertical-align: baseline;
  transition: .1s linear;
}

.table-box .sdw-table__td-has-children.is-open {
  transform: rotate(90deg);
}

.table-box .sdw-table__td-has-children:hover {
  cursor: pointer;
}

.tdContent.children {
  margin-left: 16px;
}

.tdContent .no-children {
  display: inline-block;
  margin-left: 24px;
}
