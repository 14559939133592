.monitor-eye-detail__title-label__is-effect,
.monitor-eye-detail__title-label__no-effect {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
  margin-right: 4px;
}

.monitor-eye-detail__title-label__is-effect {
  background: #265cf0;
}

.sdw-monitor-eye__wrap .sdw-monitor-eye__date-range-picker-wrap .el-input__inner {
  height: 42px;
  border: 1px solid #eee;
}

.sdw-monitor-eye__wrap .sdw-monitor-eye__date-range-picker-wrap .el-date-editor--daterange.el-input {
  width: 230px;
}
