.ticket-label-rules-0 { 
  /* 未测试 */
  color: #F56C6C;
}

.ticket-label-rules-1 { 
  /* 测试通过 */
  color: #67C23A;
}

.ticket-label-rules-2 { 
  /* 测试未通过 */
  color: #F56C6C;
}

.ticket-label-rules-3 { 
  /* 已启用 */
  color: #409EFF;
}
