.main-info__title-main {
    padding: 16px 20px;
    line-height: 12px;
    font-size: 14px;
}

.label-detail__wrap .main-info__1,
.label-detail__wrap .standard-log {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border-left: 4px solid #265cf0;
}

.label-detail__wrap .main-info__2,
.label-detail__wrap .standard-log {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
}

.label-detail__wrap .main-info__title {
    padding: 16px 20px;
    line-height: 20px;
    font-size: 18px;
    color: #262626;
    border-bottom: 1px solid #eee;
}

.label-detail__wrap .main-info__1 .main-info__img {
    margin-left: 1px;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
}

.label-detail__wrap .main-info__1 .main-info__img:hover {
    cursor: pointer;
}

.main-info__wrap {
    height: 40px;
    line-height: 40px;
    background: #fff;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 12px;
}

.main-info__wrap .main-info__label {
    color: #265cf0;
    border: 1px solid #92adf7;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 4px;
    background-color: #e9eefd;
    margin-right: 8px;
}

.page-info {
    margin-top: 10px;
}

.page-info__title {
    padding: 16px 20px 10px;
    line-height: 22px;
    font-size: 16px;
    color: #262626;
}

.page-info__content {
    padding: 20px 12px;
}

.page-info__content .info-title {
    display: inline-block;
    width: 80px;
    font-size: 14px;
    color: #999999;
    margin-right: 10px;
}

.page-info__content .info-text {
    display: inline-block;
    word-break: break-word;
    width: calc(100% - 126px);
    vertical-align: text-top;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
}

.page-info__content div {
    margin-bottom: 16px;
}

.page-detail {
    margin: 10px 10px 24px 0;
    line-height: 22px;
}

.page-detail .detail-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.page-detail .detail-enable {
    margin: 0 -5px 0 20px;
    font-size: 11px;
}

.page-detail .enable__is-effect::before,
.page-detail .enable__no-effect::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #cccccc;
    margin-left: 20px;
    margin-right: -10px;
}

.page-detail .enable__is-effect::before {
    background: #265cf0;
}
