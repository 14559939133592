.label-rule-config__title-wrap {
  font-size: 18px;
  margin-bottom: 16px;
  padding-left: 8px;
  border-left: 2px solid #265cf0;
}

.label-rule-config__group-wrap {
  padding: 8px;
  box-sizing: border-box;
  background-color: #f7f9ff;
  border-radius: 4px;
}

.label-rule-config__group-label-span {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 8px;
  width: 50%;
}

.label-rule-config__example-wrap {
  display: flex;
  margin-bottom: 16px;
}

.label-rule-config__example-wrap .example-label {
  flex: 0 0 80px;
}