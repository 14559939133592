.field-item__title {
  display: inline-block;
  min-width: 96px;
  color: #999;
  margin: 10px;
}

.topic-all-class__pagination-wrap {
  position: relative;
  background-color: #fff;
  padding: 20px;
  height: 24px;
}

.topic-all-class__pagination-item {
  position: absolute;
  right: 20px;
}

.topic-all-class__dropdown-menu_wrap {
  padding: 18px 25px;
  margin-bottom: 16px;
  background-color: #fff;
  box-sizing: border-box;
  height: 60px;
}

.topic-all-class__radio-group .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  background-color: #265cf0;
  border-color: #265cf0;
  box-shadow: -1px 0 0 0 #265cf0;
}

.topic-all-class__radio-group .el-radio-button__inner:hover {
  color: #265cf0;
}

.topic-all-class__radio-group .el-radio-button__inner {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 0;
}
