.comp__flow-chart-wrap {
  display: flex;
  /* overflow: overlay; */
  background: url('./img/dot-bg.png') repeat;
  box-sizing: border-box;
}

.comp__flow-chart-wrap_tools {
  width: 100px;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding: 4px;
}

.comp__flow-chart__obj-wrap-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 每个div的上下左右4个点 */
.comp__flow-chart__obj-wrap {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #eee;
}

.comp__flow-chart_obj-point {
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ed7b2f;
  border-radius: 50%;
}

/* 没有连线功能，就不必要hover */
/* .comp__flow-chart_obj-point:hover {
  background-color: #ed7b2f;
  border-color: #fff;
} */

.comp__flow-chart_obj-point.top {
  left: 50%;
  top: -4px;
  transform: translateX(-50%);
}

.comp__flow-chart_obj-point.right {
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
}

.comp__flow-chart_obj-point.bottom {
  left: 50%;
  bottom: -4px;
  transform: translateX(-50%);
}

.comp__flow-chart_obj-point.left {
  left: -4px;
  top: 50%;
  transform: translateY(-50%);
}