.business-indicatorwin__half-width{
  display: inline-block;
  width: 50%;
  min-width: 434px;
}
.target_value_configuration-width{
  display: inline-block;
  margin-right: 8px;
}
.target_value_configuration-width-last{
  display: inline-block;
}

.target_value_configuration-wrap{
  position: relative;
  background-color: #f7f9ff;
  padding: 25px 10px 0;
  margin-bottom: 15px;
}

.target_value_configuration-wrap .el-icon-plus,
.target_value_configuration-wrap .el-icon-minus {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}

.target_value_configuration-wrap .el-icon-plus:hover,
.target_value_configuration-wrap .el-icon-minus:hover {
  color: #265cf0;
}

.target_value_configuration-style {
  margin-bottom: 10px;
}
.target_value_configuration-addtext{
  line-height: 43px;
  color: #265cf0;
  cursor: pointer;
}

.target_value_configuration-close{
  position: absolute;
  top: -8px;
  right: -6px;
  background-color: #ff0000;
  width:16px;
  height: 16px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
}

.target_value_configuration-input-minwidth .sdw-input__outer-div-wrap {
  min-width: 140px;
}