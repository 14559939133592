.el-message-content .el-message {
  border-radius: 4px;
  font-size: 14px;
  color: #262626;
}

.el-message-content .el-message.custom-success {
  background: #e6f9f6;
  border: 1px solid #01c39e;
}

.el-message-content .el-message.custom-error {
  background: #ffeeee;
  border: 1px solid #ff5e5e;
}

.el-message-content .el-message.custom-warning {
  background: #fff1db;
  border: 1px solid #fcb849;
}

.el-message-content .el-message.custom-info {
  background: #e4f6ff;
  border: 1px solid #49c2fc;
}

.el-message-content .el-message__img {
  display: none;
}

.el-message__group::before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  left: -22px;
  top: 2px;
}

.custom-success .el-message__group::before {
  background: url(./img/success@2x.png) no-repeat;
  background-size: cover;
}

.custom-error .el-message__group::before {
  background: url(./img/error@2x.png) no-repeat;
  background-size: cover;
}

.custom-warning .el-message__group::before {
  background: url(./img/warning@2x.png) no-repeat;
  background-size: cover;
}

.custom-info .el-message__group::before {
  background: url(./img/info@2x.png) no-repeat;
  background-size: cover;
}

.el-message-content .el-message__group p {
  color: #262626;
}

.el-message__closeBtn.el-icon-close {
  font-size: 10px;
  top: 4px;
}