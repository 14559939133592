@font-face {
  font-family: "iconfont";
  src: url('iconfont.eot?t=c4d001e71407eca2e87c13c9747ce690'); /* IE9 */
  src: url('iconfont.eot?t=c4d001e71407eca2e87c13c9747ce690#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('iconfont.woff?t=c4d001e71407eca2e87c13c9747ce690') format('woff2'),
  url('iconfont.woff?t=c4d001e71407eca2e87c13c9747ce690') format('woff'), /* chrome、firefox */
  url('iconfont.ttf?t=c4d001e71407eca2e87c13c9747ce690') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('iconfont.svg?t=c4d001e71407eca2e87c13c9747ce690#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_24_one:before {
  content: "\e001";
}
.icon-icon_24_two:before {
  content: "\e002";
}
.icon-icon_24_three:before {
  content: "\e003";
}
.icon-icon_30_flexibleaccount:before {
  content: "\e004";
}
.icon-icon_30_leagueofkings:before {
  content: "\e005";
}
.icon-icon_30_yzf:before {
  content: "\e006";
}
.icon-icon_30_jcfw:before {
  content: "\e015";
}
.icon-xzb_20:before {
  content: "\e01c";
}
.icon-xbb_20:before {
  content: "\e01d";
}
.icon-xzl_20:before {
  content: "\e01e";
}
.icon-zyhb_20:before {
  content: "\e01f";
}
.icon-jjhb_20:before {
  content: "\e024";
}
.icon-bjhb_20:before {
  content: "\e025";
}
.icon-yhb_20:before {
  content: "\e026";
}
.icon-zhb_20:before {
  content: "\e027";
}
.icon-batch:before {
  content: "\e029";
}
.icon-search:before {
  content: "\e02b";
}
.icon-edit:before {
  content: "\e02c";
}
.icon-arrow-right:before {
  content: "\e02d";
}
.icon-arrow-down:before {
  content: "\e030";
}
.icon-delete:before {
  content: "\e031";
}
.icon-close:before {
  content: "\e032";
}
.icon-refresh:before {
  content: "\e036";
}
.icon-add:before {
  content: "\e037";
}
.icon-download:before {
  content: "\e038";
}
.icon-setup:before {
  content: "\e03a";
}
