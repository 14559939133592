.test-button-string{
    height: 60px;
    display: flex;
    margin-bottom: 10px;
}
.test-button {
    width: 116px;
    padding-right: 20px;
}
.test-display-string{
    line-height: 35px;
    width: 965px;
    /*flex-grow: 1;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
}
.required_new {
    width: 4px;
    height: 42px;

}
.test-api-div{
    display: flex;
}
.test-api-span{
    display: block;
    width: 60px;
    height: 42px;
    line-height: 42px;
    text-align: right;
    padding-right: 10px;
}
.required::before {
    content: '*';
    width: 0;
    display: inline-block;
    color: #FF5E5E;
    position: relative;
    left: -10px;
}
