.edit-data-win-wrap span.label {
  display: inline-block;
  width: 100px;
  float: left;
  text-align: left;
  line-height: 45px;
}

.edit-data-win-wrap span.label.required::before {
  content: '*';
  width: 0;
  display: inline-block;
  color: #FF5E5E;
  position: relative;
  left: -10px;
}

.edit-data-win-wrap > div {
  margin-bottom: 18px;
  min-height: 20px;
}

.el-tabs__content {
  overflow: inherit;
}

.calculate {
  width: 50%;
  float: left;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.calculateList {
  height: 265px;
  overflow: hidden;
  overflow-y: auto;
  float: left;
  width: 100%;
}

.calculateList li {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  /* height: 23px; */
  padding: 8px;
  cursor: pointer;
  color: #000;
  min-height: 40px;
  border: none!important;
  display: block;
}

.calculateList li:hover{
  background-color: #20a0ff;
  color: #fff;
}

.calculate-menu .calculate-icon {
  color: #265cf0;
  padding: 5px 8px;
  background-color: #fff;
  display: inline;
  margin-right: 8px;
  font-size: 15px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  float: left;
  margin-bottom: 8px;
}

.el-textarea.is-disabled .el-textarea__inner {
  color: #101010;
}

.formInput-decoration_ids .content .el-select {
  width: 100%;
  padding-right: 8px;
  position: relative;
  box-sizing: border-box;
}

.formInput-decoration_ids .content .el-select input {
  min-height: 42px !important;
}

.formInput-decoration_ids .sdw-input__div-wrap {
  width: 100% !important;
  box-sizing: border-box;
}

.formInput-decoration_ids .sdw-input__div-wrap input {
  box-sizing: border-box;
}

.formInput-store_dimension .content .el-select input {
  width: 100%;
  min-height: 42px !important;
}

.formInput-store_dimension .el-select .el-tag,  .formInput-decoration_ids .el-select .el-tag {
  background-color: #f5f5f5;
  border-color: #ebebeb;
  color: #656262;
}

.formInput-store_dimension .content .content-content {
  width: 100%;
  position: relative;
}

.formInput-store_dimension .content .content-content .el-select {
  width: 90%;
  margin-right: 8px;
  margin-bottom: 8px;
}

.formInput-store_dimension .content .content-content .el-icon-plus, .formInput-store_dimension .content .content-content .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px!important;
  border: none;
  background: #eee;
  vertical-align: bottom;
  border-radius: 24px;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  line-height: none !important;
  position: absolute;
  top: 9px;
}

.formInput-store_dimension .content .content-content .el-icon-minus {
  margin-left: 44px;
  color: rgb(255, 94, 94) !important;
}

.editorMain-Derived .content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 100px;
  position: relative;
}

.formInput-store_dimension .content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 100px;
  position: relative;
}

.formInput-enalble .el-switch{
  height: 40px;
  line-height: 52px;
}

.formInput-time_schedule .el-select {
  width: 44.5%;
  margin-right: 8px;
  height: 42px;
  position: relative;
}

.formInput-time_schedule-content {
  position: relative;
  margin-bottom: 8px;
}

.formInput-time_schedule .el-icon-plus, .formInput-time_schedule .el-icon-minus {
  cursor: pointer;
  color: rgb(38, 92, 240);
  padding: 5px!important;
  border: none;
  background: #eee;
  vertical-align: bottom;
  border-radius: 24px;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  line-height: none !important;
  position: absolute;
  top: 9px;
}

.formInput-time_schedule .el-icon-minus {
  margin-left: 36px;
  color: rgb(255, 94, 94) !important;
}


.formInput-time_schedule .el-select input {
  min-height: 42px;
}

.formInput-time_schedule .content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 100px;
  position: relative;
}
.rank_type .content {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 40px;
  display: block;
  padding-top: 10px;
}
.formInput-calculate {
  display: block;
  /* height: 50px; */
  overflow: hidden;
}

.formInput-calculate .content {
  width: 80%;
  box-sizing: border-box;
  /* padding-left: 100px; */
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid #F6F6F6;
  border-radius: 4px 4px 0 0;
}

.formInput-calculate .calculate-title {
  background: #f7f9ff;
  padding: 0 20px;
  margin-bottom: 8px;
  height: 40px;
  line-height: 40px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #999999;
}

.formInput-calculate .calculate-targetData {
  padding-left: 20px;
}

.formInput-calculate .calculate-targetData input {
  height: 28px !important;
  line-height: 28px;
  font-weight: 400;
}

.formInput-calculate .calculate-back {
  text-align: right;
  color: #265cf0;
  cursor: pointer;
  float: right;
}

.calculate-add-num {
  margin-right: 16px;
  position: relative;
}

.formInput-calculate .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  height: 255px !important;
  border-color: #eee;
}

.calculate-menu {
  padding-right: 20px;
}

.add-num-input {
  display: inline-block;
  width: 170px;
  transform-origin: center top;
  z-index: 2020;
  position: absolute;
  top: 28px;
  left: -130px;
  overflow: hidden;
  background: #fff;
  min-width: 150px;
  border: 1px solid #ebeef5;
  padding: 12px;
}

.add-num-input .el-input {
  float: left;
  width: 100px;
}

.add-num-input .el-input input {
  text-align: center;
}

.add-num-input Button {
  float: left;
  margin-left: 8px;
}

.el-tabs__nav-wrap.is-scrollable {
  padding: 0;
}

.el-tabs__nav-prev {
  display: none;
}

.el-tabs__nav-next {
  display: none;
}

.sdw-cascader__wrap {
  border: 1px solid #eee;
}
