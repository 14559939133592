.label-title-bar {
    font-size: 18px;
    margin-bottom: 16px;
    padding-left: 8px;
    border-left: 2px solid #265cf0;
}

.label-title-des {
    margin-left: 10px;
    color: #999;
    font-size: 12px;
}

.label-title-tip {
    color: #999;
    font-size: 12px;
    margin-left: 80px;
}

.label-title-btn {
    font-size: 12px;
}

.main-body {
    margin: 30px 10px;
}

.form-add .el-icon-plus,
.form-add .el-icon-minus {
    cursor: pointer;
    color: rgb(38, 92, 240);
    padding: 5px !important;
    border: none;
    background: #eee;
    vertical-align: bottom;
    border-radius: 24px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    top: 9px;
    right: -30px;
}

.form-add .el-icon-minus {
    right: -60px;
}

.form-add .el-icon-minus {
    margin-left: 36px;
    color: rgb(255, 94, 94) !important;
}

.form-add .content-one {
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
    width: 80%;
    box-sizing: border-box;
}

.body-left {
    margin-right: 8px;
}

.footer-switch {
    cursor: pointer;
    margin: 20px 0;
}

.footer-button {
    cursor: pointer;
    background-color: transparent;
    margin-top: 8px;
    display: inline-flex;
    font-size: 14px;
    border-radius: 4px;
}

.editor-main {
    width: 685px;
    margin-bottom: 20px;
}

.collapse-borderstyle {
    border: 1px solid #EEEEEE;
    border-top: transparent;
    border-radius: 6px;
}

.collapse-listitem {
    width: 100%;
    border-top: 1px solid #EEEEEE;
}

.collapse-listitem-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: rgb(246, 246, 246);
}

.collapse-listitem-title-text {
    margin-left: 15px;
}

.collapse-listitem-content {
    width: 100%;
    padding: 25px;
    border-top: 1px solid #EEEEEE;
    box-sizing: border-box;
}

.access-body .label-wrap {
    margin-top: 16px;
    display: flex;
}

.access-body .label {
    display: inline-block;
    width: 80px;
    text-align: right;
}

.access-body {
    line-height: 36px;
    position: relative;
}

.add-config-rule-btn {
    display: inline-block;
    padding: 10px 16px;
    border: 1px #ccc dashed;
    border-radius: 2px;
    color: #999;
}

.add-config-rule-btn::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../img/add@2x.png) no-repeat;
    background-size: cover;
    vertical-align: text-top;
    margin-right: 2px;
    position: relative;
    top: 2px;
}

.add-config-rule-btn:hover {
    color: #265cf0;
    cursor: pointer;
    border-color: #265cf0;
}

.add-config-rule-btn:hover::before {
    background: url(../img/add_hover@2x.png) no-repeat;
    background-size: cover;
}
.collapse-listitem-content .data-editor-win-wrap span.label {
    display: inline-block;
    width: 70px;
    float: left;
}
