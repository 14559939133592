.detail-EditMidTable__wrap-box{
    height: 200px;
    overflow: scroll;
}
.detail-midTableView__flow-chart-wrap {
    display: flex;
    width: 113px;
    height: 32px;
    color: #000000;
    cursor: pointer;
    z-index: 2;
}

.type_left_0{
    background: #265CF0;
}
.type_right_0{
    background: #E5ECFF;
}
.type_left_1{
    background: #F06326;
}
.type_right_1{
    background: #FFDCCD;
}
.detail-midTableView__flow-chart-wrap_left {
    width: 6px;
    height: 32px;
}
.detail-midTableView__flow-chart-wrap_right {
    width: 107px;
    height: 32px;
    line-height: 32px;
    border-radius:0px 4px 4px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}
