.data-govern__table-temp-config__table-type {
  font-size: 18px;
  font-weight: 600;
}

.data-govern__table-temp-config__table-type::before {
  content: '';
  display: inline-block;
  width: 3px;
  height: 22px;
  vertical-align: text-bottom;
  background-color: #265CF0;
  margin-right: 4px;
}