.add-AddSource-win__label .label__title {
    display: inline-block;
    width: 85px;
    text-align: left;
    margin-right: 8px;
}
.add-AddSource-win__label ~ .add-AddSource-win__label {
    margin-top: 16px;
}

