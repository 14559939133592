.datavisualization-flex-left-right{
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 83vh;
}

.datavisualization-left-nav {
    background-color: #FFFFFF;
    width: 320px;
    height: auto;
    border-radius: 4px 0 0 4px;
    padding: 10px 8px 0 8px;
    margin-right: 2px;
}

.datavisualization-left-hide {
    display: none;
}

.datavisualization-left-switch {
    margin: 2px 2px 10px 6px;
    color: #262626;
    font-size: 14px;
    line-height: 38px;
}

.datavisualization-left-switch .title {
    margin: 2px 2px 10px 0;
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
}

.datavisualization-left-switch .switch_text {
    margin-left: 8px;
    position: absolute;
    top: 13px;
}

.datavisualization-right-content {
    background-color: #FFFFFF;
    width: 100%;
    height: auto;
    border-radius: 0 4px 4px 0;
    padding: 8px;
}

.datavisualization-right-content .datavisualization-right-content-center {
    background-color: #FBFBFB;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-image: url(./img/empty@2x.png);
    background-size: 240px 224px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 40%
}

.datavisualization-right-img-sort {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 296px;
    top: 22px;
    cursor: pointer;
}

.datavisualization-right-img-open {
    width: 16px;
    height: 72px;
    position: absolute;
    z-index: 1000;
    top: 45%;
    left: 328px;
    cursor: pointer;
}

.datavisualization-right-img-close {
    width: 16px;
    height: 72px;
    position: absolute;
    z-index: 1000;
    top: 45%;
    left: 0;
    cursor: pointer;
}

.datavisualization-button-plus {
    display: inline-block;
    color: rgb(81, 125, 243);
    cursor: pointer;
    padding: 2px;
}

.datavisualization-button-plus::before {
    display: inline-block;
    content: '';
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-image: url('img/icon_plus.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.datavisualization-button-plus:hover {
    width: 240px;
    height: 24px;
    cursor: pointer;
    background: #e9eefd;
    border-radius: 4px;
}

.datavisualization-left-nav .sdw-empty__wrap {
    height: 230px;
    width: 300px;
    background: #fff;
    position: relative;
    user-select: none;
}