.sdw-collapse{
  width:100%;
}
.sdw-collapse-listitem{
  width:100%;
  border-top: 1px solid #EEEEEE;
  /* border-bottom: 1px solid #EEEEEE; */
}
.sdw-collapse-listitem-title{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  height: 50px;
  background-color: rgb(246, 246, 246);
}
.sdw-collapse-listitem-content{
  width:100%;
  padding: 25px;
  border-top: 1px solid #EEEEEE;
  /* border-bottom: 1px solid #EEEEEE; */
  /* border-bottom: transparent; */
  box-sizing: border-box;
  /* background-color: antiquewhite; */
  min-height: 170px;
}

.data-editor-win-wrap.indicators-source .formInput.sdw-collapse-borderstyle .sdw-collapse-listitem-content .content.checkbox-warp-style .sdw-checkbox__wrap {
  width: inherit;
}

.sdw-collapse__icon {
  position: absolute;
  top: 18px;
  right: 15px;
  display: inline-block;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-size: cover;
  background-color: rgb(246, 246, 246);
}

.sdw-collapse__drop-down {
  background-image: url('../img/down.png');
  background-repeat: no-repeat;
  transform-origin: center center;
  transition: transform .3s;
}

.sdw-collapse__drop-up {
  transform: rotate(-180deg);
}

.sdw-collapse-list-wrap {
  width:100px;
  /* position: absolute;
  bottom:0; */

  background: #fff;
  overflow: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
  z-index: 2040;
  border-radius: 4px;
  transition: height .2s;
}

.sdw-collapse-list__item {
  padding: 10px 30px 10px 10px;
  line-height: 20px;
  color: rgb(138, 138, 138);
  cursor: pointer;
}
.sdw-collapse-list__item:hover {
  background: #f5f7fa;
}

.el-switch-enable .el-switch{
  line-height: 52px;
  vertical-align: middle;
}
.checkbox-warp-style .sdw-checkbox__wrap{
  line-height: 44px;
  vertical-align: middle;
  margin-right: 16px;
}

.rank-dimension-warp .el-select{
  width: 416px;
}
.rank-el-select-style .el-select{
  width: 716px;
}

.formInput-time_schedule .formInput-time_schedule-content .sdw-select-input__wrap{
  width: 40% !important;
  margin-right: 8px;
}

.sdw-collapse-listitem-plus{
  display: inline-block;
  color: rgb(81, 125, 243);
  cursor: pointer;
}
.sdw-collapse-listitem-plus::before {
  display: inline-block;
  content: '';
  position: relative;
  top: 3px;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  background-image: url('../img/icon_plus.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.indicators-source .sdw-input__div-wrap{
  width: 98% !important;
}
.sdw-collapse-borderstyle{
  border: 1px solid #EEEEEE;
  border-top: transparent;
  border-bottom: transparent;
  margin-bottom: 0px !important;
}
.sdw-collapse-listitem-title-increatment{
  box-sizing: border-box;
  border: 1px solid #EEEEEE;
  border-top: transparent;
}
.sdw-collapse-listitem-title-text{
  margin-left: 15px;
}
.sdw-collapse-listitem-title-text{
  margin-left: 15px;
}
.sdw-collapse-listitem-title-text2 {
  margin: 0px auto;
}
