.data-govern__business-data-upload {
  padding-top: 16px;
  min-height: 600px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
}

.data-govern__business-data-upload .center-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 600px;
  height: 400px;
  margin-top: 100px;
}

.data-govern__business-data-upload .center-box > div {
  margin-bottom: 24px;
}