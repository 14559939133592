.sdw-mul-radiobox__wrap {
  display: inline-block;
  line-height: 40px;
  user-select: none;
}

.sdw-mul-radiobox__wrap > span {
  border: 1px solid #eee;
  padding: 4px 20px 4px 0;
  outline: none;
  margin-bottom: 8px;
  display: inline-block;
  line-height: 24px;
  margin-left: -1px;
}

.sdw-mul-radiobox__wrap > span:hover {
  color: #265cf0;
}

.sdw-mul-radiobox__wrap > span:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #eee
}

.sdw-mul-radiobox__wrap > span:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sdw-mul-radiobox__wrap > span input {
  position: relative;
  top: 2px;
  visibility: hidden;
}

.sdw-mul-radiobox__wrap:hover {
  cursor: pointer;
}

.sdw-mul-radiobox__wrap span.radiobox-text {
  margin-left: 4px;
}
.deep span.current {
  color: #fff;
  background: #265cf0;
}
.light span.current {
  border: 1px solid #265CF0;
  margin-right: 1px;
  color: #265CF0;
  background: rgba(38,92,240,0.08);
}
