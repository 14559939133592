/* 会话框的样式：独立出来 */

.chat-area {
  padding: 16px 16px 12px 14px;
  position: relative;
  background: #fafafa;
}

.conversation {
  margin-bottom: 20px;
  position: relative;
}

.kf-side {
  text-align: right;
  padding-left: 54px;
  box-sizing: border-box;
  overflow: hidden;
}

.conversation .kf-side .avater,
.conversation .user-side .avater {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
}

.kf-side .cont, .kf-side .cont .kf-info {
  position: relative;
}

.kf-side .cont {
  padding-right: 54px;
  float: right;
}

.conversation .kf-side .kf-name {
  margin-top: 3px;
}

.kf-side .cont .text {
  background: #e6ecff;
  border-radius: 1px;
  text-align: justify;
  padding: 10px;
  position: relative;
  max-width: 640px;
}

.text {
  font-size: 14px;
  color: #000;
  position: relative;
  word-break: break-all;
  word-wrap: break-word;
}

.chat-area img {
  max-width: 300px;
  max-height: 300px;
}

.conversation .user-side {
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
}

.user-side:last-child {
  margin-bottom: 0;
}

.conversation .user-side .avater {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.user-side .cont {
  position: relative;
  padding-left: 54px;
  float: left;
}

.user-side .user-name {
  margin-top: 3px;
}

.user-info .text {
  background: #ebedf3;
  border-radius: 1px;
  text-align: justify;
  padding: 10px;
  max-width: 640px;
}

.user-side {
  display: inline-block;
  padding-right: 140px;
  margin-bottom: 10px;
}

.chat-area .history .desc {
  font-size: 12px;
  color: #ccc;
  text-align: center;
  margin: 5px 0;
}

.conversation .user-side .avater .iconfont,
.conversation .kf-side .avater .iconfont {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover !important;
 }

.conversation .user-side .avater .user {
  background: url('../../img/icon_36_user@2x.png') no-repeat;
}

.conversation .kf-side .avater .kf {
  background: url('../../img/icon_36_kf@2x.png') no-repeat;
}
