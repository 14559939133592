.market-metadata-tabledetails-authApply .title {
    padding:20px 0px 20px 20px;
    border-bottom:3px solid #f6f6f6;
}
.market-metadata-tabledetails-authApply .body{
    display:flex;
}
.market-metadata-tabledetails-authApply .body .left{
    flex: auto;
    border-right: 3px solid #f6f6f6;
}
.market-metadata-tabledetails-authApply .body .left .left_body{
    padding: 20px 20px 20px 30px;
}
.market-metadata-tabledetails-authApply .body .left .left_body .lable-wrap {
    width: 80%;
    margin-bottom:30px;
}
.market-metadata-tabledetails-authApply .body .left .left_body .lable-wrap .required::before {
    content: '*';
    width: 0;
    display: inline-block;
    color: #FF5E5E;
    position: relative;
    left: -10px;
}
.market-metadata-tabledetails-authApply .body .left .lable-title {
    display: inline-block;
    width: 100px;
}
.market-metadata-tabledetails-authApply .body .right{
    flex: none;
    width: 700px;
}
.market-metadata-tabledetails-authApply .body .right .right-body{
    text-align: center;
    padding: 20px 20px 50px 20px;
}
