.add-application-win__label ~ .add-application-win__label {
  margin-top: 18px;
}

.add-application-win__label .label__title {
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 8px;
}
