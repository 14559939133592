.machFactory .page-search label {
    width: 80px;
    float: left;
    text-align: left;
    margin-right: 0px;
    font-size: 14px;
}

.machFactory .page-search .el-form-item__content {
    float: left;
    width: calc(100% - 80px);
}

.machFactory .page-search input {
    height: 40px;
    line-height: 40px;
}

.machFactory .page-search .el-form-item {
    margin-right: 40px;
}

.propertyInfo {
    display: inline-block;
    float: left;
    width: 22%;
}

.editorTwo .editorTwo-input {
    width: 45%;
    float: left;
}

.editorTwo .editorTwo-input input {
    width: 100% !important;
}

.editorTwo .editorTwo-input div{
    width: 80%;
}

.editorTwo .content {
    position: relative;
}

.editorOne .editorTwo-input {
    width: 45%;
    float: left;
}

.editorOne .editorTwo-input input {
    width: 100% !important;
}

.editorOne .editorTwo-input div{
    width: 80%;
}

.editorTwo .contentOne {
    width: 100%;
    display: block;
}