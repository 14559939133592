.header-nav__disabled-button,
.header-nav__disabled-button:hover {
  display: inline-block;
  padding: 18px 30px;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
  transition: all .2s ease-in-out;
}

.header-nav__disabled-button:hover {
  cursor: not-allowed;
}

.menu__item.disabled:hover {
  background-color: transparent;
}

.show-down-icon {
  margin-left: 8px;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background: url(../img/down@2x.png) no-repeat right;
  background-size: cover;
  transition: 1s;
  transform-origin: center;
}

.menu__item.current .show-down-icon {
  background: url(../img/down_hover@2x.png) no-repeat right;
  background-size: cover;
}

.menu__item:hover .show-down-icon {
  transform: rotate(180deg);
  transform-origin:inherit;
  transition: 1s;
  background: url(../img/down_white@2x.png) no-repeat right;
  background-size: cover;
}

.m-head__logo:hover{
  cursor: pointer;
}
