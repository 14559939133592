.audit_info__wrap {
  position: relative;
  width: 56px;
  height: 100%;
}

.audit_info__wrap_tip_div {
  width: 56px;
  height: 56px;
}

.audit_info__wrap .help {
  width: 56px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: -56px;
  transition: all .3s;
}
.audit_info__wrap .help:hover {
  background-color: #265CF0;
}

.audit_info__wrap .sdw-popover__wrap_top {
  width: 56px;
  height: 19px;
  position: absolute;
  background: black;
  opacity: 0.7;
  color: rgb(255, 255, 255);
  padding: 6px 20px;
  border-radius: 4px;
  transform: translateX(-50%) translateY(-100%);
  z-index: 999999;
}

.audit_info__wrap .sdw-popover__wrap_top::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #000;
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
  left: 50%;
  margin-left: -4px;
}
.audit_info__wrap .sdw-popover__wrap_top_hidden{
  display: none;
}
.audit_info__wrap .help .info_img_help {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 32px;
  height: 32px;
  background: url('../img/icon-32-heip.svg') no-repeat center center;
}
.audit_info__wrap .help .info_img_help.is_img_enter_help {
  background: url('../img/icon-32-heip-hover.svg') no-repeat center center;
}

.audit_info__wrap .user-email {
  width: 56px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  transition: all .3s;
}
.audit_info__wrap .user-email:hover {
  background-color: #265CF0;
}
.audit_info__wrap .user-email .info_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 22px;
  height: 18px;
  background: url('../img/info.svg') no-repeat center center;
}
.audit_info__wrap .user-email .info_img.is_img_enter {
  background: url('../img/info_hover.svg') no-repeat center center;
}

.show_red_hot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF5E5E;
  position: absolute;
  top: -4px;
  right: -4px;
  border: 1px solid #fff;
  box-sizing: border-box;
}

.audit_info__wrap .info__dialog {
  cursor: auto;
  position: absolute;
  top: 68px;
  width: 400px;
  border-radius: 4px;
  background-color: #fff;
  transform: translateX(-40%);
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.06);
}

.audit_info__wrap .info__dialog::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  position: absolute;
  top: -4px;
  left: 46%;
  transform: rotate(45deg);
}

.audit_info__wrap .info__dialog .header {
  height: 60px;
}
.audit_info__wrap .info__dialog .header .sdw__tabs-page-title {
  font-size: 14px;
}

.audit_info__wrap .info__dialog .body {
  overflow: overlay;
}
.audit_info__wrap .info__dialog .body .inner-box {
  padding: 18px;
}
.audit_info__wrap .info__dialog .body .inner-box ~ .inner-box {
  border-top: 1px solid #eee;
}
.audit_info__wrap .info__dialog .body .tag {
  display: inline-block;
  width: 76px;
  height: 24px;
  line-height: 24px;
  color: #265CF0;
  background: #eaeffb;
  border-radius: 2px;
  text-align: center;
}
.audit_info__wrap .info__dialog .body .box_wrap {
  display: flex;
  margin-bottom: 8px;
}
.audit_info__wrap .info__dialog .body .title {
  display: inline-block;
  width: 240px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 8px;
  font-weight: 600;
}
.audit_info__wrap .info__dialog .body .label {
  width: 84px;
  height: 20px;
  color: #999;
  flex: none;
}
.audit_info__wrap .info__dialog .body .label_text {
  width: 100%;
  max-height: 400px;
  overflow: overlay;
  text-overflow: ellipsis;
}
.audit_info__wrap .info__dialog .body .label.time {
  width: auto;
}
.audit_info__wrap .info__dialog .body .label_text.pass {
  color: #265CF0;
}
/* 0: 审批中   1：通过   2：驳回 */
.audit_info__wrap .info__dialog .body .label_text.pass_2 {
  color: #ff5e5e;
}
.audit_info__wrap .info__dialog .body .label_text.pass_1 {
  color: #01c39e;
}
.audit_info__wrap .info__dialog .body .label_text.pass_0 {
  color: #265CF0;
}

.audit_info__wrap .info__dialog .footer {
  text-align: center;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.06);
}
.audit_info__wrap .info__dialog .footer:hover {
  background-color: #265CF0;
  color: #fff;
}
