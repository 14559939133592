.sdw-monitor-quality-control-wrap .sdw-monitor-eye__date-range-picker-wrap {
  position: relative;
}

.sdw-monitor-quality-control-wrap .sdw-monitor-eye__date-range-picker-wrap .el-date-editor.is-have-trigger.is-filled::before {
  content: '预警时间';
  display: inline-block;
  width: 72px;
  height: 40px;
  padding: 0 8px;
  line-height: 40px;
  background: #fff;
}

.sdw-monitor-quality-control-wrap .sdw-monitor-eye__date-range-picker-wrap .el-date-editor.is-have-trigger.is-filled::before,
.sdw-monitor-quality-control-wrap .sdw-monitor-eye__wrap .sdw-monitor-eye__date-range-picker-wrap .el-input__inner,
.sdw-monitor-quality-control-wrap .el-date-editor.is-have-trigger.is-active.is-filled input.el-input__inner {
  border-color: transparent;
}

.sdw-monitor-quality-control-wrap .el-date-editor.is-have-trigger.is-active.is-filled input.el-input__inner,
.sdw-monitor-quality-control-wrap span.el-date-editor.is-have-trigger.is-active.is-filled input.el-input__inner {
  border-color: transparent;
}

.sdw-monitor-quality-control-wrap .sdw-monitor-eye__date-range-picker-wrap .el-date-editor {
  margin-left: -4px;
  box-sizing: border-box;
}

.sdw-monitor-quality-control-wrap .sdw-monitor-eye__date-range-picker-wrap .el-date-editor .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sdw-monitor-quality-control-wrap span.el-date-editor.is-have-trigger.is-active.is-filled .el-input__inner {
  border-color: #eee;
}

.sdw-monitor-quality-control-wrap .el-date-editor {
  border: none;
}

.sdw-monitor-quality-control-wrap .page-mainbody .sdw-monitor-eye__wrap  span.el-date-editor {
  border: 1px solid #eee;
  border-radius: 4px;
  line-height: 40px;
}

.sdw-monitor-quality-control-wrap .page-mainbody .sdw-monitor-eye__wrap  span.el-date-editor.is-active {
  border: 1px solid #265cf0;
}

.sdw-monitor-quality-control-wrap span.el-date-editor::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 87px;
  background-color: #eee;
}
.sdw-monitor-quality-control-wrap span.el-date-editor.is-active::after {
  background-color: #265cf0;
}

.sdw-monitor-quality-control-wrap .sdw-monitor-eye__quality-conctrol__date-label {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px 0 0 4px;
  line-height: 40px;
  position: absolute;
  left: 0;
  top: -11px;
  z-index: 9;
  background: #fff;
}

.con-add-application-win__label {
  margin-bottom: 16px;
}

.con-add-application-win__label .label__title {
  display: inline-block;
  width: 98px;
}
