.data-editor-win-wrap span.label {
  display: inline-block;
  width: 100px;
  float: left;
}

.data-editor-win-wrap span.label.required::before {
  content: '*';
  width: 0;
  display: inline-block;
  color: #FF5E5E;
  position: relative;
  left: -10px;
}

.data-editor-win-wrap > div {
  margin-bottom: 18px;
}

.el-dialog__body {
  margin-bottom: 16px;
}

.show-data {
  width: 50%;
  height: 50%;
}

.DialogShowData .seach .field{
  width: 30%;
  float: left;
}

.DialogShowData .seach .source{
  width: 46%;
  float: left;
  overflow: hidden;
}

.DialogShowData .el-dialog--tiny {
  width: auto;
  max-width: 50%;
}

.DialogShowData .el-date-editor--daterange.el-input {
  min-height: 42px;
  width: 100%;
}

.DialogShowData .el-date-editor {
  width: 100%;
}

.DialogShowData .el-input__icon+.el-input__inner {
  padding-right: 35px;
  height: 42px;
  width: 100%;
}

.show-data-list {
  margin-top: 66px;
  max-height: 500px;
  overflow: auto;
}

.DialogShowRunData .source {
  margin: 0 !important;
}

.DialogShowRunData .source .title {
  padding-right: 20px;
}

.DialogShowRunData .seach {
  display: block;
  overflow: hidden;
  margin-bottom: 18px;
}

.DialogShowRunData .menu {
  text-align: right;
}

.DialogShowRunData .menu button {
  width: 98px;
  height: 40px;
}

.show-data.DialogShowRunData .el-dialog--tiny {
  width: 80% !important;
}