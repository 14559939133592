.sdw-tree-wrap .group-has-children,
.sdw-tree-wrap .group-has-no-children {
  line-height: 36px;
  user-select: none;
  max-width: 100%;
}

.sdw-tree-wrap .group-has-children {
  display: inline-block;
  width: 100%;
}

.sdw-tree-wrap .group-has-children:hover,
.sdw-tree-wrap .group-has-no-children:hover {
  cursor: pointer;
  background: #e9eefd;
}

.sdw-tree-wrap .group-has-children::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(./img/tree_group@2x.png) no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.sdw-tree-wrap .group-has-children.expend::before {
  transform: rotate(90deg);
}

.sdw-tree-wrap .group-has-children.current,
.sdw-tree-wrap .group-has-no-children.current {
  color: #265cf0;
}

.sdw-tree-wrap {
  height: 100%;
}

.sdw-tree__body-wrap {
  height: 88%;
  overflow: auto;
}
