.add-AddField-win__label {
    display: flex;
    margin-bottom: 16px;
}

.add-AddField-win__label .label__title {
    display: inline-block;
    width: 88px;
    text-align: left;
    line-height: 40px;
}
/*.add-AddField-win__label ~ .add-AddField-win__label {*/
/*    margin-top: 16px;*/
/*}*/
/*.add-AddField-win__label .filed_type_div{*/
/*    width: 460px;*/
/*}*/

.add-AddField-win__label .sdw-select-list-wrap {
    position: absolute;
}

.add-AddField-win__label .el-icon-plus,
.add-AddField-win__label .el-icon-minus {
    cursor: pointer;
    color: rgb(38, 92, 240);
    padding: 5px;
    border: none;
    background: #eee;
    vertical-align: bottom;
    border-radius: 24px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    margin-top: 9px;
}

.add-AddField-win__label .el-icon-minus {
    margin-left: 42px;
    color: rgb(255, 94, 94);
}

.add-AddField-win__label .el-checkbox+.el-checkbox {
    margin-left: 0;
}

.popover-list-wrap {
    position: absolute;
    background: #fff;
    margin-top: 6px;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
    z-index: 10020;
    border-radius: 4px;
    height: 220px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.popover-list-wrap-up {
    top: -250px;
}

.popover-list-wrap .content {
    width: 600px;
    flex-grow: 1;
    overflow-y: auto;
}

.popover-list-wrap .buttons {
    height: 20px;
    margin-top: 10px;
    /*display: flex;*/
    /*justify-content: space-between;*/
}

.popover-list-wrap .buttons .btn {
    position: absolute;
    bottom: 2px;
    right: 12px;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #265cf0 !important;
    border-color: #265cf0 !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #d1dbe5;
    border-color: #d1dbe5;
}
