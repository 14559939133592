.standard-distribution__title-wrap {
  font-size: 18px;
  background-color: #fff;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.standard-distribution__title-button {
  position: absolute;
  right: 10px;
  top: 3px;
  padding: 20px;
}
