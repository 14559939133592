.comp-collapse-item-wrap .header-item-wrap {
  box-sizing: border-box;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #eee;
}

.comp-collapse-item-wrap .header-item-wrap .title-wrap {
  position: relative;
}

.comp-collapse-item-wrap .header-item-wrap .title-wrap .icon-arrow-right {
  position: absolute;
  right: 20px;
  color: #999;
  cursor: pointer;
  transition: all 0.3s;
}
.comp-collapse-item-wrap .header-item-wrap .title-wrap .icon-arrow-right.is-active {
  transform: rotate(90deg);
}

.body-context-wrap {
  max-height: 0px;
  overflow: overlay;
  overflow: hidden;
  transition: all .7s;
}
.body-context-wrap.is-active {
  max-height: 20000px;
  transition: all 3s;
}

.comp-collapse-item-wrap .context-wrap {
  display: flex;
  line-height: 28px;
  margin-top: 8px;
}

.comp-collapse-item-wrap .context-wrap:last-of-type {
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.comp-collapse-item-wrap .context-wrap .div-wrap {
  box-sizing: border-box;
  padding: 8px 20px;
  word-break: break-word;

}

.comp-collapse-item-wrap .context-wrap .div-wrap .btn {
  cursor: pointer;
  color: #265CF0;
}
