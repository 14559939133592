.visualWin-win__label ~ .visualWin-win__label {
    margin-top: 16px;
}

.visualWin-win__label .label__title {
    display: inline-block;
    vertical-align: text-top;
    width: 80px;
    text-align: left;
    margin-right: 8px;
}

.sort-win-dialog__wrap .sdw-dialog-win__content-header {
    padding: 25px 10px;
    border-bottom: 1px solid #eee;
}

.sort-win-dialog__wrap .sdw-dialog-win__content-body {
    padding: 0 20px;
    height: 670px !important;
    max-height: 700px !important;
    overflow: auto;
}

.sort-win-dialog__wrap .sdw-dialog-win__content-header .cancel-img {
    position: absolute;
    top: 18px;
}

/* 当前页面输入框禁用样式 */
.sort-win-dialog__label .sdw-input__wrap:disabled {
    color: #666666;
    background: #f6f6f6;
    border: 1px solid #eeeeee;
    cursor: not-allowed;
}

/* Tabs标签页 标题样式 */
.sort-win-dialog__label .el-tabs__item {
    padding: 0 16px;
    height: 41px;
    box-sizing: border-box;
    line-height: 41px;
    display: inline-block;
    list-style: none;
    font-size: 16px !important;
    color: #262626 !important;
    position: relative;
}

/* Tabs标签页 标题选中样式 */
.sort-win-dialog__label .el-tabs__item.is-active {
    color: #265CF0 !important;
}

/* Tabs标签页 标题选中下划线样式 */
.sort-win-dialog__label .el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #265CF0 !important;
    z-index: 1;
    transition: transform .3s cubic-bezier(.645,.045,.355,1);
    list-style: none;
}

/* Tabs标签页吸顶 */
.sort-win-dialog__label .el-tabs__header {
    position: fixed;
    top: 0;
    margin-top: 10px;
}

/* 弹窗中的select需要单独设置样式 */
.sort-win-dialog__label .sdw-select-list-wrap {
    /* 弹窗中的select需要传入 listwidth */
    position: absolute;
}

/* 排序弹窗内容样式 */
.report-sort-win__wrap {
    margin: 20px;
    position: relative;
}

/* 屏蔽下拉框清除按钮 */
.report-sort-win__wrap .sdw-select-clearable {
    display: none;
}

/* Dropdown下拉菜单 按钮样式 */
.sort-win-dialog__label .el-dropdown-link {
    cursor: pointer;
}

/* Dropdown下拉菜单 下拉框样式 */
.sort-win-dialog__label .el-dropdown-menu {
    height: 550px;
    overflow: auto;
    margin: 5px 0;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06) !important;
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    padding: 6px 0;
    position: absolute;
    top: 20px !important;
    left: 0 !important;
    min-width: 300px;
    max-width: 500px;
}

.sort-win-dialog__label .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f7f9ff;
    color: #262626;
}
