.sdw-checkbox__wrap input[type='checkbox'] {
  width: 14px;
  height: 14px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: relative;
  margin-top: -3px;
  outline: none;
  cursor: pointer;
}

.sdw-checkbox__wrap input[type='checkbox']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-sizing: content-box;
  outline: none;
}

.sdw-checkbox__wrap input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #265cf0 url('./img/checkbox_img@2x.svg') no-repeat center;
  background-size: 12px;
  border: 1px solid #265cf0;
  border-radius: 2px;
  outline: none;
}

.sdw-checkbox__wrap {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sdw-checkbox__wrap:hover {
  cursor: pointer;
}

.sdw-checkbox__wrap .checkbox__text {
  margin-left: 8px;
}
