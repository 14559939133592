.page-chart__wrap .m-chart__title .title-txt:hover {
  color: #265cf0;
  cursor: pointer;
}

.dictionary__panel-theme {
  flex: 1;
}

.dictionary__panel-theme ~ .dictionary__panel-theme {
  margin-left: 16px;
}

.page-chart__wrap,
.m-panel-theme {
  border-radius: 4px;
}

.m-panel-theme:hover {
  box-shadow: 0 2px 12px 0 rgba(38,38,38,.1);
}

.m-panel-theme .panel-theme-target .target-txt {
  padding: 8px 0;
  font-size: 14px;
  color: #999;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: normal;
  box-sizing: border-box;
}

.m-panel__list {
  flex-wrap: nowrap;
}

.dictionary__pc-image {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 4px;
}

.dictionary__right-img {
  display: inline-block;
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  margin-left: 8px;
  background: url('../img/icon_12_xl.png') no-repeat;
}

.sdw-dictionary__wrap.page-chart__wrap .m-chart-sector .m-chart__header {
  margin-bottom: 0;
}

.m-title__txt.more-button {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}

.m-title__txt.more-button:hover {
  color: #265cf0;
}

.m-chart-sector__canvas-wrap {
  width: 360px;
  height: 260px;
}

.m-chart-sector__canvas-wrap div:hover {
  cursor: pointer !important;
}
