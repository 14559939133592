.control-win__display-wrap {
  line-height: 44px;
}

.control-win__display-wrap ~ .control-win__display-wrap {
  margin-top: 16px;
}

.control-win__title-label {
  display: inline-block;
  width: 86px;
  padding-right: 10px;
}

.control-win__half-width {
  display: inline-block;
  width: 50%;
}

.control-win__title-label.add-strategy {
  color: #265cf0;
}
.control-win__title-label.add-strategy:hover {
  cursor: pointer;
}

.control-win__warn-value-label {
  display: inline-block;
  padding: 0 8px;
  border: 1px solid #ddd;
  line-height: 40px;
  background: #eee;
}

.sdw-warn-strategy__wrap ~ .sdw-warn-strategy__wrap {
  margin-left: 96px;
}

.control-win__title-label.warn-strategy {
  position: relative;
}

.control-win__title-label.warn-strategy .el-icon-minus {
  position: absolute;
  left: -42px;
  top: 6px;
  color: #ff5e5e;
  border: 2px solid;
  border-radius: 50%;
  padding: 6px;
  transform: scale(0.5);
}

.control-win__title-label.warn-strategy .el-icon-minus:hover {
  cursor: pointer;
}

.warn-add-input-group-wrap {
  display: inline-block;
  width: 740px;
  line-height: 42px;
}
.warn-add-input-group-wrap ~ .warn-add-input-group-wrap {
  margin-top: 14px;
  margin-left: 96px;
}

.control-win__title-label__unit {
  display: inline-block;
  border-radius: 4px;
  line-height: 40px;
  border: 1px solid #eee;
  margin-left: 10px;
  padding: 0 10px;
}

.control-win__display-wrap.cycle_time .sdw-input__div-wrap {
  height: 40px;
}
