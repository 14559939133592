.page-main__wrap.data-management .page-search .btn-group button {
  width: 120px;
}

.btn-group button:hover {
  background: rgba(38,92,240,0.08) !important;
  border: 1px solid #265CF0 !important;
  color: #265CF0 !important;
}

.page-main__wrap.data-management .data-management__pagenation-wrap {
  margin-top: 16px;
  text-align: right;
}

.sdw-data-management__search-input-list-wrap {
  position: fixed;
  z-index: 99;
  width: 500px;
  height: 400px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
  overflow: scroll;
  margin-top: 8px;
}

.sdw-data-management__search-input-list-wrap > div {
  padding: 10px;
  height: 20px;
  line-height: 20px;
}

.sdw-data-management__search-input-list-wrap > div:hover {
  background-color: #f5f7fa;
  cursor: pointer;
}

.sdw-data-management__search-input-list-wrap > div.selected {
  background-color: rgb(38, 92, 240);
}