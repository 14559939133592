.sdw-input-tip__wrap {
  position: relative;
  display: inline-block;
}

.sdw-input-tip__wrap--tip-div {
  position: absolute;
  top: 42px;
  z-index: 999;
  border: 1px solid #eee;
  width: inherit;
  max-height: 600px;
  overflow: overlay;
}

.sdw-input-tip__wrap--tip-div--item {
  line-height: 40px;
  padding-left: 16px;
  background-color: #fff;
  cursor: pointer;
}

.sdw-input-tip__wrap--tip-div--item:hover {
  background-color: #eee;
}

.sdw-input-tip__wrap--tip-div--item.current {
  color: #265cf0;
}